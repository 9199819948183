<template>
    <div class="auth" :class="{ hide: !visible }">
        <div class="auth-overlay" @click="closeModal()"></div>
        <div class="auth__content">
            <div class="auth__item" v-show="visible && type === 'login'">
                <button class="auth__close" @click="closeModal()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path d="M24 26.24L16.16 34.08C15.8666 34.3733 15.4933 34.52 15.04 34.52C14.5866 34.52 14.2133 34.3733 13.92 34.08C13.6266 33.7866 13.48 33.4133 13.48 32.96C13.48 32.5066 13.6266 32.1333 13.92 31.84L21.76 24L13.92 16.16C13.6266 15.8666 13.48 15.4933 13.48 15.04C13.48 14.5866 13.6266 14.2133 13.92 13.92C14.2133 13.6266 14.5866 13.48 15.04 13.48C15.4933 13.48 15.8666 13.6266 16.16 13.92L24 21.76L31.84 13.92C32.1333 13.6266 32.5066 13.48 32.96 13.48C33.4133 13.48 33.7866 13.6266 34.08 13.92C34.3733 14.2133 34.52 14.5866 34.52 15.04C34.52 15.4933 34.3733 15.8666 34.08 16.16L26.24 24L34.08 31.84C34.3733 32.1333 34.52 32.5066 34.52 32.96C34.52 33.4133 34.3733 33.7866 34.08 34.08C33.7866 34.3733 33.4133 34.52 32.96 34.52C32.5066 34.52 32.1333 34.3733 31.84 34.08L24 26.24Z" fill="#503B2F"/>
                    </svg>
                </button>
                <h2 class="auth__title">Iniciar sesión</h2>
                <!-- <div class="auth__logo"><img src="../assets/logo.png" /></div> -->
                <form class="auth__inputs" @submit="onLoginSubmit">
                    <p class="auth-error" v-show="login.error">{{ login.error }}</p>
                    <!-- EMAIL -->
                    <input class="auth__input" 
                    v-model="login.email"
                    type="email" 
                    placeholder="Email" 
                    required>
                    <!-- PASSWORD -->
                    <input class="auth__input" 
                    v-model="login.password"
                    type="password" 
                    placeholder="Contraseña" 
                    required>
                    
                    <a class="auth__remember" @click="modalChange('remember')">No recuerdo mi contraseña</a>
                    <EDCButton label="Iniciar sesión" />
                    <a class="login-google" @click="onGoogleLogin()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 10 20 10C22.5492 10 24.8684 10.9617 26.6342 12.5325L31.3484 7.81834C28.3717 5.04418 24.39 3.33334 20 3.33334C10.7959 3.33334 3.33337 10.7958 3.33337 20C3.33337 29.2042 10.7959 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#FFC107"/>
                            <path d="M5.255 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 10 20 10C22.5492 10 24.8683 10.9617 26.6342 12.5325L31.3483 7.81834C28.3717 5.04418 24.39 3.33334 20 3.33334C13.5983 3.33334 8.04667 6.94751 5.255 12.2425Z" fill="#FF3D00"/>
                            <path d="M20 36.6667C24.305 36.6667 28.2166 35.0192 31.1741 32.34L26.0158 27.975C24.2863 29.2903 22.1729 30.0017 20 30C15.665 30 11.9841 27.2358 10.5975 23.3783L5.16248 27.5658C7.92081 32.9633 13.5225 36.6667 20 36.6667Z" fill="#4CAF50"/>
                            <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7618 25.1804 27.5778 26.7943 26.0133 27.9758L26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#1976D2"/>
                        </svg>
                        Entra con Google
                    </a>
                </form>
                <hr>
                <p class="auth__secondary-option">¿No tienes una cuenta? <a @click="modalChange('register')">Regístrate</a></p>
            </div>
            <div class="auth__item" v-show="visible && type === 'register'">
                <button class="auth__close" @click="closeModal()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path d="M24 26.24L16.16 34.08C15.8666 34.3733 15.4933 34.52 15.04 34.52C14.5866 34.52 14.2133 34.3733 13.92 34.08C13.6266 33.7866 13.48 33.4133 13.48 32.96C13.48 32.5066 13.6266 32.1333 13.92 31.84L21.76 24L13.92 16.16C13.6266 15.8666 13.48 15.4933 13.48 15.04C13.48 14.5866 13.6266 14.2133 13.92 13.92C14.2133 13.6266 14.5866 13.48 15.04 13.48C15.4933 13.48 15.8666 13.6266 16.16 13.92L24 21.76L31.84 13.92C32.1333 13.6266 32.5066 13.48 32.96 13.48C33.4133 13.48 33.7866 13.6266 34.08 13.92C34.3733 14.2133 34.52 14.5866 34.52 15.04C34.52 15.4933 34.3733 15.8666 34.08 16.16L26.24 24L34.08 31.84C34.3733 32.1333 34.52 32.5066 34.52 32.96C34.52 33.4133 34.3733 33.7866 34.08 34.08C33.7866 34.3733 33.4133 34.52 32.96 34.52C32.5066 34.52 32.1333 34.3733 31.84 34.08L24 26.24Z" fill="#503B2F"/>
                    </svg>
                </button>
                <h2 class="auth__title">Registro</h2>
                <!-- <div class="auth__logo"><img src="../assets/logo.png" /></div> -->
                <form class="auth__inputs" @submit="onRegisterSubmit">
                    <p class="auth-error" v-show="register.error">{{ register.error }}</p>
                    <!-- FIRSTNAME -->
                    <input 
                    v-model="register.first_name"
                    class="auth__input" 
                    type="text" 
                    placeholder="Nombre"
                    required>
                    <!-- LASTNAME -->
                    <input 
                    v-model="register.last_name"
                    class="auth__input" 
                    type="text" 
                    placeholder="Apellido"
                    required>
                    <!-- EMAIL -->
                    <input 
                    v-model="register.email"
                    class="auth__input" 
                    type="email" 
                    placeholder="Email"
                    required>
                    <!-- PASSWORD -->
                    <input 
                    v-model="register.password"
                    class="auth__input" 
                    type="password" 
                    placeholder="Contraseña"
                    required>
                    <p class="auth__terms-label">
                        <router-link :to="{ name: 'terms' }" target="_blank">Términos y condiciones</router-link>
                    </p>
                    <EDCButton label="Registrarme" />
                    <a class="login-google" @click="onGoogleRegister()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 10 20 10C22.5492 10 24.8684 10.9617 26.6342 12.5325L31.3484 7.81834C28.3717 5.04418 24.39 3.33334 20 3.33334C10.7959 3.33334 3.33337 10.7958 3.33337 20C3.33337 29.2042 10.7959 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#FFC107"/>
                            <path d="M5.255 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 10 20 10C22.5492 10 24.8683 10.9617 26.6342 12.5325L31.3483 7.81834C28.3717 5.04418 24.39 3.33334 20 3.33334C13.5983 3.33334 8.04667 6.94751 5.255 12.2425Z" fill="#FF3D00"/>
                            <path d="M20 36.6667C24.305 36.6667 28.2166 35.0192 31.1741 32.34L26.0158 27.975C24.2863 29.2903 22.1729 30.0017 20 30C15.665 30 11.9841 27.2358 10.5975 23.3783L5.16248 27.5658C7.92081 32.9633 13.5225 36.6667 20 36.6667Z" fill="#4CAF50"/>
                            <path d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7618 25.1804 27.5778 26.7943 26.0133 27.9758L26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z" fill="#1976D2"/>
                        </svg>
                        Regístrate con Google
                    </a>
                </form>
                <hr>
                <p class="auth__secondary-option">¿Tienes una cuenta? <a @click="modalChange('login')">Inicia sesión</a></p>
            </div>
            <div class="auth__item" v-show="visible && type === 'remember'">
                <button class="auth__close" @click="closeModal()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path d="M24 26.24L16.16 34.08C15.8666 34.3733 15.4933 34.52 15.04 34.52C14.5866 34.52 14.2133 34.3733 13.92 34.08C13.6266 33.7866 13.48 33.4133 13.48 32.96C13.48 32.5066 13.6266 32.1333 13.92 31.84L21.76 24L13.92 16.16C13.6266 15.8666 13.48 15.4933 13.48 15.04C13.48 14.5866 13.6266 14.2133 13.92 13.92C14.2133 13.6266 14.5866 13.48 15.04 13.48C15.4933 13.48 15.8666 13.6266 16.16 13.92L24 21.76L31.84 13.92C32.1333 13.6266 32.5066 13.48 32.96 13.48C33.4133 13.48 33.7866 13.6266 34.08 13.92C34.3733 14.2133 34.52 14.5866 34.52 15.04C34.52 15.4933 34.3733 15.8666 34.08 16.16L26.24 24L34.08 31.84C34.3733 32.1333 34.52 32.5066 34.52 32.96C34.52 33.4133 34.3733 33.7866 34.08 34.08C33.7866 34.3733 33.4133 34.52 32.96 34.52C32.5066 34.52 32.1333 34.3733 31.84 34.08L24 26.24Z" fill="#503B2F"/>
                    </svg>
                </button>
                <div class="auth__logo"><img src="../assets/logo.png" /></div>
                <form class="auth__inputs" @submit="onRememberSubmit">
                    <p class="auth-error" v-show="remember.error">{{ remember.error }}</p>
                    <input class="auth__input" type="email" placeholder="Email" v-model="remember.email">
                    <div class="auth__buttons">
                        <EDCButton label="Recuperar contraseña" />
                        <a class="edc-button secondary" @click="modalChange('login')">Iniciar sesión</a>
                    </div>
                </form>
            </div>
            <div class="auth__item" v-show="visible && type === 'update'">
                <button class="auth__close" @click="closeModal()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path d="M24 26.24L16.16 34.08C15.8666 34.3733 15.4933 34.52 15.04 34.52C14.5866 34.52 14.2133 34.3733 13.92 34.08C13.6266 33.7866 13.48 33.4133 13.48 32.96C13.48 32.5066 13.6266 32.1333 13.92 31.84L21.76 24L13.92 16.16C13.6266 15.8666 13.48 15.4933 13.48 15.04C13.48 14.5866 13.6266 14.2133 13.92 13.92C14.2133 13.6266 14.5866 13.48 15.04 13.48C15.4933 13.48 15.8666 13.6266 16.16 13.92L24 21.76L31.84 13.92C32.1333 13.6266 32.5066 13.48 32.96 13.48C33.4133 13.48 33.7866 13.6266 34.08 13.92C34.3733 14.2133 34.52 14.5866 34.52 15.04C34.52 15.4933 34.3733 15.8666 34.08 16.16L26.24 24L34.08 31.84C34.3733 32.1333 34.52 32.5066 34.52 32.96C34.52 33.4133 34.3733 33.7866 34.08 34.08C33.7866 34.3733 33.4133 34.52 32.96 34.52C32.5066 34.52 32.1333 34.3733 31.84 34.08L24 26.24Z" fill="#503B2F"/>
                    </svg>
                </button>
                <div class="auth__logo"><img src="../assets/logo.png" /></div>
                <form class="auth__inputs" @submit="onUpdateSubmit">
                    <p class="auth-error" v-show="update.error">{{ update.error }}</p>
                    <input class="auth__input" type="text" placeholder="Nombre" v-model="update.firstname">
                    <input class="auth__input" type="text" placeholder="Apellido" v-model="update.lastname">
                    <div class="auth__buttons">
                        <EDCButton label="Actualizar" />
                        <a class="edc-button secondary" @click="closeModal()">Cancelar</a>
                    </div>
                </form>
            </div>
            <div class="auth__item" v-show="visible && type === 'change'">
                <button class="auth__close" @click="closeModal()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
                        <path d="M24 26.24L16.16 34.08C15.8666 34.3733 15.4933 34.52 15.04 34.52C14.5866 34.52 14.2133 34.3733 13.92 34.08C13.6266 33.7866 13.48 33.4133 13.48 32.96C13.48 32.5066 13.6266 32.1333 13.92 31.84L21.76 24L13.92 16.16C13.6266 15.8666 13.48 15.4933 13.48 15.04C13.48 14.5866 13.6266 14.2133 13.92 13.92C14.2133 13.6266 14.5866 13.48 15.04 13.48C15.4933 13.48 15.8666 13.6266 16.16 13.92L24 21.76L31.84 13.92C32.1333 13.6266 32.5066 13.48 32.96 13.48C33.4133 13.48 33.7866 13.6266 34.08 13.92C34.3733 14.2133 34.52 14.5866 34.52 15.04C34.52 15.4933 34.3733 15.8666 34.08 16.16L26.24 24L34.08 31.84C34.3733 32.1333 34.52 32.5066 34.52 32.96C34.52 33.4133 34.3733 33.7866 34.08 34.08C33.7866 34.3733 33.4133 34.52 32.96 34.52C32.5066 34.52 32.1333 34.3733 31.84 34.08L24 26.24Z" fill="#503B2F"/>
                    </svg>
                </button>
                <div class="auth__logo"><img src="../assets/logo.png" /></div>
                <form class="auth__inputs" @submit="onChangePasswordSubmit">
                    <p class="auth-error" v-show="change_pass.error">{{ change_pass.error }}</p>
                    <input class="auth__input" type="password" placeholder="Contraseña actual" v-model="change_pass.current">
                    <input class="auth__input" type="password" placeholder="Nueva contraseña" v-model="change_pass.new">
                    <input class="auth__input" type="password" placeholder="Repetir nueva contraseña" v-model="change_pass.new_2">
                    <div class="auth__buttons">
                        <EDCButton label="Actualizar" />
                        <a class="edc-button secondary" @click="closeModal()">Cancelar</a>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { Auth } from '@/services/auth';
import { AuthModal } from '../scripts/open-auth';
import { Alert } from '@/scripts/open-alert.js';
import { Spinner } from '@/scripts/open-spinner';

import EDCButton from '../components/Button.vue';

export default {
    name: "edc-modal-auth",
    components: {
        EDCButton
    },
    emits: ["close", "change"],
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        type: {
            type: String,
            required: true,
            validator(value) {
                return ['login', 'register', 'remember', 'update', 'change'].includes(value)
            }
        }
    },
    data() {
        return {
            login: {
                email: null,
                password: null,
                error: null
            },
            register: {
                email: null,
                password: null,
                first_name: null,
                last_name: null,
                error: null
            },
            remember: {
                email: null,
                error: null 
            },
            update: {
                firstname: Auth.getUserDetails().firstname,
                lastname: Auth.getUserDetails().lastname,
                error: null
            },
            change_pass: {
                current: null,
                new: null,
                new_2: null,
                error: null
            }
        }
    },
    updated() {
        this.update.firstname = Auth.getUserDetails().firstname
        this.update.lastname = Auth.getUserDetails().lastname
    },
    methods: {
        closeModal() {
            this.$emit('close')

            this.login = { email: null, password: null, error: null };
            this.register = { email: null, first_name: null, last_name: null, password: null, error: null };
            this.remember = { email: null, error: null }
            this.change_pass = { current: null, new: null, new_2: null, error: null }
            this.update.error = null 
        },
        modalChange(type) {
            this.$emit('change', type)
        },
        onLoginSubmit(event) {
            event.preventDefault()
            
            this.showSpinner(true)
            Auth.login(this.login.email, this.login.password).then(data => {
                this.showSpinner(false)
                if (data.status === 400) {
                    this.login.error = data.msg
                } else {
                    this.login.error = null
                    this.login.email = null
                    this.login.password = null
                    AuthModal.closeModals()
                    Alert.open("success", "Sesión iniciada correctamente")
                }
            })
        },
        onRegisterSubmit(event) {
            event.preventDefault()

            this.showSpinner(true)
            Auth.register(
                this.register.email, 
                this.register.first_name, 
                this.register.last_name, 
                this.register.password
            )
            .then(data => {
                this.showSpinner(false)
                
                if (data.status === 400) {
                    this.register.error = data.msg
                } else {
                    AuthModal.closeModals()
                    this.login.error = null
                    this.register.email = null
                    this.register.first_name = null
                    this.register.last_name = null
                    this.register.password = null
                }
            })
        },
        onRememberSubmit(event){
            event.preventDefault()

            this.showSpinner(true)
            Auth.remember(this.remember.email).then(data => {
                this.showSpinner(false)
                Alert.open("success", data.msg)
                
                if (data.status === 400) {
                    this.remember.error = data.msg
                } else {
                    this.remember.email = null
                    this.remember.error = null
                    AuthModal.closeModals()
                }
            })
        },
        onUpdateSubmit(event) {
            event.preventDefault()

            this.showSpinner(true)
            
            Auth.update(this.update.firstname, this.update.lastname)
            .then((data) => {
                this.showSpinner(false)
                Alert.open("success", data.msg)
                AuthModal.closeModals()
            })
        },
        onChangePasswordSubmit(event) {
            event.preventDefault()

            if (this.change_pass.new !== this.change_pass.new_2) {
                this.change_pass.error = "Las contraseñas no coinciden"
                return;
            }

            this.showSpinner(true)
            Auth.changePassword(this.change_pass.current, this.change_pass.new)
            .then(data => {
                this.showSpinner(false)
                if (data.status === 400) {
                    this.change_pass.error = data.msg;
                } else {
                    Alert.open("success", data.msg)
                    this.closeModal()
                }
            })
        },
        showSpinner(show) {
            if (!show) {
                Spinner.hide()
            } else {
                Spinner.show()
            }
        },
        // Google Auth
        onGoogleLogin() {
            AuthModal.closeModals()
            this.$router.push({ name: "login-provider" })
        },
        onGoogleRegister() {
            AuthModal.closeModals()
            this.$router.push({ name: "login-provider" })
        }
    }
}
</script>

<style>
.hide {
    display: none;
    pointer-events: none;
    opacity: 0;
}

.auth-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.auth {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.auth__content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 24px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
}

.auth-error {
    color: red;
    font-weight: bold;
    text-align: center;
    width: 100%;
}

.auth__item {
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-light);
    padding: 24px 40px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
}

.auth__logo {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth__logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.auth__close {
    position: absolute;
    right: 16px;
    top: 16px;
    background: none;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.auth__close svg {
    width: 40px;
    height: 40px;
}

.auth__inputs {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
    margin: 24px 0px;
}

.auth__inputs input {
    width: 100%;
}

.auth__inputs button {
    margin-top: 16px;
}

.auth__secondary-option {
    text-align: center;
    font-size: 1.1rem;
}

.auth__remember {
    font-size: 1rem;
    color: var(--color-primary);
    font-weight: bold;
    cursor: pointer;
}

.auth__secondary-option a {
    text-decoration: none;
    color: var(--color-primary);
    font-weight: bold;
    cursor: pointer;
}

.auth__terms-label {
    width: 100%;
    font-size: 1rem;
    text-align: center;
}

.auth__terms-label a {
    color: var(--color-primary);
    font-weight: bold;
}

.auth__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
}

.auth__buttons button {
    margin: 0;
}

.login-google {
    width: 100%;
    background: none;
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    font-size: 1rem;

    background-color: white;
    padding: 8px 10px;
    padding-right: 20px;
    border-radius: 200px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    cursor: pointer;
    transition: all .25s;
}

.login-google svg {
    width: 30px;
    height: 30px;
}

.login-google:hover {
    background-color: #f1f1f1;
}
</style>