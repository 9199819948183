<template>
    <div :class="`edc-experience ${type}`" @click="navigateToExperienceViewer(id)">
        <div class="edc-experience__bk"><img :src="image" ></div>
        <div class="edc-experience__pointer" v-if="hand">
            <svg class="edc-experience__pointer-circles" xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
                <path class="edc-experience__pointer-circle-1" opacity="0.2" fill-rule="evenodd" clip-rule="evenodd" d="M17 2C8.71573 2 2 8.71573 2 17C2 25.2843 8.71573 32 17 32C25.2843 32 32 25.2843 32 17C32 8.71573 25.2843 2 17 2ZM0 17C0 7.61116 7.61116 0 17 0C26.3888 0 34 7.61116 34 17C34 26.3888 26.3888 34 17 34C7.61116 34 0 26.3888 0 17Z" fill="black"/>
                <path class="edc-experience__pointer-circle-2" opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M17 9C12.5817 9 9 12.5817 9 17C9 21.4183 12.5817 25 17 25C21.4183 25 25 21.4183 25 17C25 12.5817 21.4183 9 17 9ZM7 17C7 11.4772 11.4772 7 17 7C22.5228 7 27 11.4772 27 17C27 22.5228 22.5228 27 17 27C11.4772 27 7 22.5228 7 17Z" fill="black"/>
            </svg>
            <svg class="edc-experience__pointer-hand" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
                <path d="M51.25 47.5H23.75V55H51.25V47.5Z" fill="white" stroke="black" stroke-width="4" stroke-linejoin="round"/>
                <path d="M23.75 47.5C15.6013 38.5962 10.925 33.4375 9.7225 32.02C7.91875 29.895 8.67625 27.495 13.1913 27.495C17.7063 27.495 20.31 34.1012 23.75 34.1012C23.7713 34.1062 23.7725 25.6575 23.7538 8.755C23.7531 8.26233 23.8495 7.77436 24.0375 7.31898C24.2255 6.86359 24.5014 6.44972 24.8494 6.101C25.1975 5.75228 25.6108 5.47556 26.0658 5.28665C26.5208 5.09773 27.0086 5.00033 27.5013 5H27.505C27.9982 4.99984 28.4866 5.09684 28.9424 5.28547C29.3981 5.47411 29.8122 5.75067 30.161 6.09937C30.5098 6.44807 30.7865 6.86208 30.9753 7.31774C31.1641 7.77339 31.2613 8.26178 31.2613 8.755V18.7675C41.2263 20.2775 46.645 21.1112 47.515 21.2675C48.8213 21.5025 51.25 22.75 51.25 26.335V47.5H23.75Z" fill="white" stroke="black" stroke-width="4" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="edc-experience__info">
            <p class="edc-experience__info-type">{{ municipio }}</p>
            <p class="edc-experience__info-title">{{ name }}</p>
        </div>
        <div class="edc-experience__type">
            <img v-if="type === 'AR'" src="../assets/exps/ar.svg" />
            <img v-if="type === 'OV'" src="../assets/exps/ova.svg" />
            <img v-if="type === 'CS'" src="../assets/exps/capsule.svg" />
            <img v-if="type === 'V3'" src="../assets/exps/360.svg" />
        </div>
    </div>
</template>

<script>
export default {
    name: "edc-experience",
    props: {
        id: {
            type: Number,
            required: true
        },
        hand: {
            type: Boolean,
            required: false,
            default: false
        },
        type: {
            type: String,
            required: true,
            validator(value) {
                return ['V3', 'AR', 'CS', 'OV'].includes(value)
            }
        },
        image: {
            type: String,
            required: true,
            default: "https://picsum.photos/800/800?random=1930"
        },
        name: {
            type: String,
            required: true,
            default: 'Nombre de la experiencia'
        },
        municipio: {
            type: String,
            required: true
        }
    },
    methods: {
        navigateToExperienceViewer(id) {
            this.$router.push({ name: 'play-viewer', params: { id: id }})
        }
    }
}
</script>

<style>
.edc-experience {
    width: 100%;
    height: 200px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
    --exp-color: #A153AA;
}

.edc-experience.AR { --exp-color: #A153AA }
.edc-experience.V3 { --exp-color: #B1CD4B }
.edc-experience.OV { --exp-color: #529CD5 }
.edc-experience.CS { --exp-color: #529CD5 }

.edc-experience__bk {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
}

.edc-experience__bk img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.edc-experience__type {
    position: absolute;
    top: 8px;
    left: 8px;
}

.edc-experience__type img {
    width: 50px;
    height: 50px;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 1));
}

.edc-experience__info {
    width: 100%;
    padding: 14px 18px;
    background-color: var(--exp-color);
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 0px 0px 16px 16px;
    overflow: hidden;
}

.edc-experience__info::before {
    content: '';
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 68.65%, rgba(255, 255, 255, 0.50) 100%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.edc-experience__info-title {
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
}

.edc-experience__info-type {
    color: white;
    font-weight: 400;
    font-size: 0.9rem;
}

.edc-experience__pointer {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 24px;
    right: 10px;
    pointer-events: none;
}

.edc-experience__pointer-circles {
    position: absolute;
    top: -12px;
    left: 10px;
    z-index: -1;
}

.edc-experience__pointer-circle-1 {
    animation-name: PointerCircle;
    animation-duration: 1s;
    animation-delay: 0.65s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    opacity: 0;
}

.edc-experience__pointer-circle-2 {
    animation-name: PointerCircle;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    opacity: 0;
}

@keyframes PointerCircle {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    50% {
        opacity: 1
    }
    
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
</style>