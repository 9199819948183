<template>
    <EDCHeader />
    <EDCHead title="Contacto" />
    <div class="edc-contact">
        <div class="contact__content">
            <p class="contact__text">Contacta con nosotros por medio de nuestras redes sociales:</p>
            <div class="contact__social-media">
                <a href="https://wa.me/+573128215824" target="_blank"><img src="../assets/social-media/phone.svg" /></a>
                <a href="https://www.facebook.com/enamoratedelcesar/" target="_blank"><img src="../assets/social-media/facebook.svg" /></a>
                <a href="https://www.instagram.com/enamoratecesar?igsh=cXJpM2Y0enkwZmJ2" target="_blank"><img src="../assets/social-media/instagram.svg" /></a>
                <a href="https://www.tiktok.com/@enamoratecesar?_t=8jhEN6pSQHm&_r=1" target="_blank"><img src="../assets/social-media/tiktok.svg" /></a>
            </div>
            <p class="contact__text">O escríbenos por medio del siguiente formulario:</p>
            <form class="contact__form" @submit="recaptcha">
                <div class="form-group">
                    <p class="form-label">Nombre:</p>
                    <input class="form-input" type="text" placeholder="Ej: John Doe" v-model="form.name" required>
                </div>
                <div class="form-group">
                    <p class="form-label">Email:</p>
                    <input class="form-input" type="email" placeholder="Ej: jdoe@gmail.com" v-model="form.email" required>
                </div>
                <div class="form-group">
                    <p class="form-label">Razón:</p>
                    <div class="edc-selector">
                        <select v-model="form.reason" required>
                            <option value="general">Consultas generales</option>
                            <option value="suggestions">Sugerencias y comentarios</option>
                            <option value="claim">Reclamaciones</option>
                            <option value="collaboration">Colaboraciones</option>
                            <option value="jobs">Oportunidades de empleo</option>
                            <option value="press">Prensa y medios de comunicación</option>
                            <option value="other">Otro</option>
                        </select>
                    </div>
                </div>
                <div class="form-group">
                    <p class="form-label">Mensaje:</p>
                    <textarea cols="30" rows="10" v-model="form.message" required></textarea>
                </div>
                <EDCButton label="Enviar" />
            </form>
        </div>
    </div>
    <EDCFooter />
    <EDCMobileNav />
</template>

<script>
import EDCHeader from '../components/Header.vue';
import EDCHead from '../components/Head.vue';
import EDCFooter from '../components/Footer.vue';
import EDCMobileNav from '../components/MobileNav.vue';
import EDCButton from '../components/Button.vue';

import { sendContact } from '../services/contact';
import { Alert } from '@/scripts/open-alert';
import { Spinner } from '@/scripts/open-spinner';

export default {
    name: "edc-contact",
    components: {
        EDCHeader,
        EDCHead,
        EDCFooter,
        EDCMobileNav,
        EDCButton
    },
    data() {
        return {
            form: {
                name: null,
                reason: null,
                email: null,
                message: null
            }
        }
    },
    methods: {
        async recaptcha(event) {
            event.preventDefault()
            Spinner.show()

            await this.$recaptchaLoaded()
            const token = await this.$recaptcha('login')
            
            sendContact(this.form.name, this.form.reason, this.form.email, this.form.message, token)
            .then(data => {
                Alert.open('success', data)
                this.form = { name: null, reason: null, email: null, message: null }
                Spinner.hide()
            })
        }
    }
}
</script>

<style>
.edc-contact {
    width: 100%;
    margin: 40px 0px;
    margin-bottom: 60px;
}

.edc-contact .contact__content {
    width: 100%;
    max-width: 400px;
    padding: 0px 24px;
    margin: 0 auto;
}

.contact .contact__text {
    width: 100%;
    text-align: center;
}

.edc-contact .contact__social-media {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;
    margin: 24px 0px;
}

.edc-contact .contact__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
}

.edc-contact .form-group {
    width: 100%;
}

.edc-contact .form-group input,
.edc-contact .form-group textarea {
    width: 100%;
}

.edc-contact .form-group textarea {
    height: 120px;
}
</style>