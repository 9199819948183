import { API } from './_API';

class MunicipioAPI {
    static async allMunicipios() {
        return await fetch(`${API}/municipios/?format=json`)
        .then(res => res.json())
        .then(data => {
            return data.data
        })
    }

    /** @param {number} identifier unique municipio identifier */
    static async getMunicipio(identifier) {
        return await fetch(`${API}/municipios/${identifier}/?format=json`)
        .then(res => res.json())
        .then(data => {
            return data.data
        })
    }

    /** @param {number} currentMunicipioID id of current municipio (will be avoided in recommendations) */
    static async getMunicipioRecommendations(currentMunicipioID) {
        return await fetch(`${API}/municipios/?format=json`)
        .then(res => res.json())
        .then(data => {
            const arr = []

            data.data.forEach(municipio => {
                if (municipio.id !== currentMunicipioID) {
                    arr.push(municipio)
                }
            })

            // Shuffles array
            for (var i = arr.length - 1; i > 0; i--) {
                var j = Math.floor(Math.random() * (i + 1));
                var temp = arr[i];
                arr[i] = arr[j];
                arr[j] = temp;
            }

            const nRecommendations = arr.slice(0, 3);
            return nRecommendations;
        })
    }
}

export {
    MunicipioAPI
}