<template>
    <div class="edc-service-detail">
        <div class="edc-service-detail__overlay" @click="onClose"></div>
        <div class="edc-service-detail__content">
            <button class="edc-service-detail__close" @click="onClose">
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="48" height="48" rx="24" fill="white"/>
                    <path d="M24 26.24L16.16 34.08C15.8667 34.3733 15.4934 34.52 15.04 34.52C14.5867 34.52 14.2134 34.3733 13.92 34.08C13.6267 33.7866 13.48 33.4133 13.48 32.96C13.48 32.5066 13.6267 32.1333 13.92 31.84L21.76 24L13.92 16.16C13.6267 15.8666 13.48 15.4933 13.48 15.04C13.48 14.5866 13.6267 14.2133 13.92 13.92C14.2134 13.6266 14.5867 13.48 15.04 13.48C15.4934 13.48 15.8667 13.6266 16.16 13.92L24 21.76L31.84 13.92C32.1334 13.6266 32.5067 13.48 32.96 13.48C33.4134 13.48 33.7867 13.6266 34.08 13.92C34.3734 14.2133 34.52 14.5866 34.52 15.04C34.52 15.4933 34.3734 15.8666 34.08 16.16L26.24 24L34.08 31.84C34.3734 32.1333 34.52 32.5066 34.52 32.96C34.52 33.4133 34.3734 33.7866 34.08 34.08C33.7867 34.3733 33.4134 34.52 32.96 34.52C32.5067 34.52 32.1334 34.3733 31.84 34.08L24 26.24Z" fill="#EB5E5E"/>
                </svg>
            </button>
            <img class="edc-service-detail__photo" :src="data?.image" />
            <!-- Food -->
            <div class="edc-service-detail__inner" v-if="type === 1">
                <h2 class="edc-service-detail__title">{{ data?.name }}</h2>
                <p class="edc-service-detail__value">Tipo: {{ data?.type }}</p>
                <br>
                <p class="edc-service-detail__label">Descripción:</p>
                <p class="edc-service-detail__value">{{ data?.description }}</p>
            </div>
            <!-- Transport -->
            <div class="edc-service-detail__inner" v-if="type === 2">
                <h2 class="edc-service-detail__title">{{ data?.name }}</h2>
                <p class="edc-service-detail__value">Vehículo: {{ data?.type }}</p>
                <p class="edc-service-detail__value">Salida: {{ data?.departure }}</p>
                <p class="edc-service-detail__value">Destino: {{ data?.destination }}</p>
                <br>
                <p class="edc-service-detail__label">Descripción:</p>
                <p class="edc-service-detail__value">{{ data?.description }}</p>
                <br>
                <p class="edc-service-detail__label">Recomendaciones:</p>
                <p class="edc-service-detail__value">{{ data?.recommendation }}</p>
            </div>
            <!-- Hotel -->
            <div class="edc-service-detail__inner" v-if="type === 3">
                <h2 class="edc-service-detail__title">{{ data?.type }}</h2>
                <p class="edc-service-detail__value">Capacidad: {{ data?.capacity }} persona(s)</p>
                <br>
                <p class="edc-service-detail__value" :class="[data?.ac ? 'active' : '']">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
                        <path d="M10.6 13.8L8.45 11.65C8.26667 11.4667 8.03333 11.375 7.75 11.375C7.46667 11.375 7.23333 11.4667 7.05 11.65C6.86667 11.8333 6.775 12.0667 6.775 12.35C6.775 12.6333 6.86667 12.8667 7.05 13.05L9.9 15.9C10.1 16.1 10.3333 16.2 10.6 16.2C10.8667 16.2 11.1 16.1 11.3 15.9L16.95 10.25C17.1333 10.0667 17.225 9.83333 17.225 9.55C17.225 9.26667 17.1333 9.03333 16.95 8.85C16.7667 8.66667 16.5333 8.575 16.25 8.575C15.9667 8.575 15.7333 8.66667 15.55 8.85L10.6 13.8ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z" />
                    </svg>
                    Aire acondicionado
                </p>
                <p class="edc-service-detail__value" :class="[data?.private_bathroom ? 'active' : '']">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" >
                        <path d="M10.6 13.8L8.45 11.65C8.26667 11.4667 8.03333 11.375 7.75 11.375C7.46667 11.375 7.23333 11.4667 7.05 11.65C6.86667 11.8333 6.775 12.0667 6.775 12.35C6.775 12.6333 6.86667 12.8667 7.05 13.05L9.9 15.9C10.1 16.1 10.3333 16.2 10.6 16.2C10.8667 16.2 11.1 16.1 11.3 15.9L16.95 10.25C17.1333 10.0667 17.225 9.83333 17.225 9.55C17.225 9.26667 17.1333 9.03333 16.95 8.85C16.7667 8.66667 16.5333 8.575 16.25 8.575C15.9667 8.575 15.7333 8.66667 15.55 8.85L10.6 13.8ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z" />
                    </svg>
                    Baño privado
                </p>
                <br>
                <p class="edc-service-detail__label">Descripción:</p>
                <p class="edc-service-detail__value">{{ data?.description }}</p>
            </div>
            <!-- Tour -->
            <div class="edc-service-detail__inner" v-if="type === 4">
                <h2 class="edc-service-detail__title">{{ data?.name }}</h2>
                <br>
                <p class="edc-service-detail__label">Descripción:</p>
                <p class="edc-service-detail__value">{{ data?.description }}</p>
                <br>
                <p class="edc-service-detail__label">Recomendaciones:</p>
                <p class="edc-service-detail__value">{{ data?.more_information }}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "edc-service-detail",
    props: {
        type: {
            type: Number,
            validator(value) {
                return [1, 2, 3, 4].includes(value)
            }
        },
        data: {
            type: Object
        }
    },
    methods: {
        onClose() {
            this.$emit('close')
        }
    }
}
</script>

<style>
.edc-service-detail {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 9999;
}

.edc-service-detail__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .75);
}

.edc-service-detail__content {
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;
}

.edc-service-detail__close {
    position: absolute;
    right: 16px;
    top: 16px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

.edc-service-detail__photo {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.edc-service-detail__inner {
    padding: 20px;
}

.edc-service-detail__label {
    font-weight: bold;
}

.edc-service-detail__value {
    display: flex;
    align-items: center;
    gap: 6px;
    fill: var(--color-dark);
}

.edc-service-detail__value.active {
    fill: var(--color-primary);
} 

.edc-service-detail__value.active svg {
    fill: var(--color-primary);
    opacity: 1;
} 

.edc-service-detail__value svg {
    opacity: 0.5;
}

@media (min-width: 920px) {
    .edc-service-detail { align-items: center }
    .edc-service-detail__content { border-radius: 16px }
}
</style>