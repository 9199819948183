import { API } from "./_API";

async function sendContact(full_name, reason, email, comment, token) {
    return await fetch(`${API}/contact_email/`, {
        method: "POST",
        body: JSON.stringify({ full_name, reason, email, comment, "g-recaptcha-response": token }),
        headers: { "Content-Type": "application/json" }
    })
    .then(res => res.json())
    .then(data => {
        return data.msg;
    })
}

export {
    sendContact
}