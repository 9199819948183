const subscribers = [];

class Spinner {
    static show() {
        this.#send({ open: true })
    }
    
    static hide(delay = 500) {
        setTimeout(() => {
            this.#send({ open: false })
        }, delay);
    }

    static #send(data) {
        subscribers.forEach(subscriber => {
            subscriber(data)
        })
    }
    
    static subscribe(fn) {
        subscribers.push(fn)
    }
    
    static unsubscribe(fn) {
        const idx = subscribers.findIndex(item => item === fn)
        subscribers.splice(idx, 1)
    }
}

export {
    Spinner
}