<template>
    <div class="edc-header-helper"></div>
    <div class="authentication-overlay" v-show="show_modal || show_options || show_search" @click="hideAllModals()"></div>
    <header class="edc-header">
        <div class="content">
            <router-link :to="{ name: 'home' }" class="logo">
                <img src="../assets/logo.png" alt="logo">
                <img src="../assets/logos/logo-upc.png" class="logo-upc" alt="logo" v-if="logo_upc">
            </router-link>
            <div class="options">
                <div class="search">
                    <div class="search__button" @click="openSearchModal()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                            <path d="M26.1333 28L17.7333 19.6C17.0667 20.1333 16.3 20.5556 15.4333 20.8667C14.5667 21.1778 13.6444 21.3333 12.6667 21.3333C10.2444 21.3333 8.19467 20.4942 6.51733 18.816C4.84 17.1378 4.00089 15.088 4 12.6667C4 10.2444 4.83911 8.19467 6.51733 6.51733C8.19556 4.84 10.2453 4.00089 12.6667 4C15.0889 4 17.1387 4.83911 18.816 6.51733C20.4933 8.19556 21.3324 10.2453 21.3333 12.6667C21.3333 13.6444 21.1778 14.5667 20.8667 15.4333C20.5556 16.3 20.1333 17.0667 19.6 17.7333L28 26.1333L26.1333 28ZM12.6667 18.6667C14.3333 18.6667 15.7502 18.0831 16.9173 16.916C18.0844 15.7489 18.6676 14.3324 18.6667 12.6667C18.6667 11 18.0831 9.58311 16.916 8.416C15.7489 7.24889 14.3324 6.66578 12.6667 6.66667C11 6.66667 9.58311 7.25022 8.416 8.41733C7.24889 9.58444 6.66578 11.0009 6.66667 12.6667C6.66667 14.3333 7.25022 15.7502 8.41733 16.9173C9.58444 18.0844 11.0009 18.6676 12.6667 18.6667Z"/>
                        </svg>
                    </div>
                    <div class="search-container" v-show="show_search">
                        <div class="search__head">
                            <button class="search__back" @click="hideAllModals()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                    <path d="M33.3333 18.3331V21.6665H13.3333L22.5 30.8331L20.1333 33.1998L6.93333 19.9998L20.1333 6.7998L22.5 9.16647L13.3333 18.3331H33.3333Z" fill="#503B2F"/>
                                </svg>
                            </button>
                            <input type="text" class="search__input" placeholder="Busca tu municipio..." @input="searchMunicipio">
                            <svg class="search__icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                                <path d="M12.6667 21.3333C10.2444 21.3333 8.19467 20.4942 6.51733 18.816C4.84 17.1378 4.00089 15.088 4 12.6667C4 10.2444 4.83911 8.19467 6.51733 6.51733C8.19556 4.84 10.2453 4.00089 12.6667 4C15.0889 4 17.1387 4.83911 18.816 6.51733C20.4933 8.19556 21.3324 10.2453 21.3333 12.6667C21.3333 13.6444 21.1778 14.5667 20.8667 15.4333C20.5556 16.3 20.1333 17.0667 19.6 17.7333L27.0667 25.2C27.3111 25.4444 27.4333 25.7556 27.4333 26.1333C27.4333 26.5111 27.3111 26.8222 27.0667 27.0667C26.8222 27.3111 26.5111 27.4333 26.1333 27.4333C25.7556 27.4333 25.4444 27.3111 25.2 27.0667L17.7333 19.6C17.0667 20.1333 16.3 20.5556 15.4333 20.8667C14.5667 21.1778 13.6444 21.3333 12.6667 21.3333ZM12.6667 18.6667C14.3333 18.6667 15.7502 18.0831 16.9173 16.916C18.0844 15.7489 18.6676 14.3324 18.6667 12.6667C18.6667 11 18.0831 9.58311 16.916 8.416C15.7489 7.24889 14.3324 6.66578 12.6667 6.66667C11 6.66667 9.58311 7.25022 8.416 8.41733C7.24889 9.58444 6.66578 11.0009 6.66667 12.6667C6.66667 14.3333 7.25022 15.7502 8.41733 16.9173C9.58444 18.0844 11.0009 18.6676 12.6667 18.6667Z" fill="#503B2F"/>
                            </svg>
                        </div>
                        <div class="search__list">
                            <router-link 
                                class="search__item"
                                v-for="item in search_items" 
                                :key="item.code" 
                                :to="{ name: 'municipio', params: { id: item.id }}"
                                @click="hideAllModals()">
                                {{ item.name }}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="nav">
                    <router-link class="link" :to="{ name: 'home' }">Inicio</router-link>
                    <router-link class="link" :to="{ name: 'play' }">Dale Play</router-link>
                    <router-link class="link" :to="{ name: 'businesses' }">Negocios</router-link>
                    <router-link class="link" :to="{ name: 'news' }">Lo Nuevo</router-link>
                    <a class="profile" v-show="isAuthenticated" @click="showProfileModal(true)">
                        <img src="../assets/profile.svg" alt="">
                    </a>
                    <div class="profile-menu" v-show="modal_profile" @click="showProfileModal(false)">
                        <div class="profile-menu__overlay"></div>
                        <div class="profile-menu__content">
                            <a class="profile-menu__item" @click="openAuthModal('update')">Editar usuario</a>
                            <a class="profile-menu__item" @click="openAuthModal('change')">Cambiar contraseña</a>
                            <a class="profile-menu__item" @click="logout()">Cerrar sesión</a>
                        </div>
                    </div>
                </div>
                <div class="authentication-container" v-show="!isAuthenticated">
                    <EDCButton label="Regístrate" class="enter" @click="show_modal = true" />
                    <!-- Register modal -->
                    <div class="authentication-modal" v-show="show_modal">
                        <div class="authentication-modal__content">
                            <p>Regístrate y disfruta de la experiencia completa con los servicios que tenemos para nuestros usuarios.</p>
                            <EDCButton label="Regístrate" @click="openAuthModal('register')" />
                            <p @click="hideAllModals()" class="authentication-modal__close">Cerrar</p>
                        </div>
                    </div>
                    <!-- User options -->
                    <div class="authentication-options" v-show="show_options">
                        <a class="athentication-item">Editar usuario</a>
                        <router-link :to="{ name: 'change-pass' }" class="athentication-item">Cambiar contraseña</router-link>
                        <a class="athentication-item red">Cerrar sesión</a>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { AuthModal } from '@/scripts/open-auth';
import { Auth } from '../services/auth';
import { MUNICIPIO_CODES } from '../scripts/municipio-codes';

import EDCButton from './Button.vue';

export default {
    name: "edc-header",
    components: {
        EDCButton
    },
    props: {
        logo_upc: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isAuthenticated: false,
            show_options: false,
            show_search: false,
            show_modal: false,
            modal_profile: false,
            search_items: []
        }
    },
    methods: {
        hideAllModals() {
            this.show_modal = false
            this.show_options = false
            this.show_search = false
        },
        searchMunicipio(e) {
            const el = e.target;
            let items = [];

            MUNICIPIO_CODES.forEach((item) => {
                const curr = item.name.toLowerCase();
                const elValue = el.value.toLowerCase();

                if(curr.includes(elValue)) {
                    items.push(item)
                }
            })

            this.search_items = items;
        },
        openSearchModal() {
            this.hideAllModals()
            this.show_search = true;
        },
        openAuthModal(type) {
            AuthModal.openModal(type)
            this.hideAllModals()
        },
        authChange(payload) {
            this.isAuthenticated = payload.logged_in
        },
        showProfileModal(show) {
            this.modal_profile = show
        },
        logout() {
            Auth.logOut()
        }
    },
    mounted() {
        this.isAuthenticated = Auth.isAuthenticated()
        Auth.subscribe(this.authChange)
    },
    beforeUnmount() {
        this.hideAllModals()
    }
}
</script>

<style scoped>
.edc-header-helper {
    height: 64px;
}
.edc-header {
    width: 100%;
    height: 64px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #FFFEEC;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: center;
    z-index: 9999;
}

.edc-header .content {
    height: 100%;
    width: 100%;
    max-width: 1000px;
    padding: 0px 12px;
    padding-left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.edc-header__back {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-left: 8px;
    text-decoration: none;
}

.edc-header__back svg {
    width: 34px;
    height: 34px;
}

.edc-header__back p {
    font-size: 1.2rem;
    font-weight: bold;
    color: var(--color-dark);
    position: relative;
    top: 2px;
}

.logo {
    width: 80px;
    height: 80px;
    background-color: #FFFEEC;
    border-radius: 0px 0px 8px 0px;
    align-self: flex-start;
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;
}

.logo-upc {
    position: relative;
    top: -7px;
    transform: scale(1.2);
}

.logo::before {
    content: '';
    width: 100%;
    height: 18px;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #FFFEEC;
    z-index: -1;
    border-radius: 0px 0px 8px 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.options {
    display: flex;
    align-items: center;
    gap: 8px;
}

.options .search__button {
    width: 48px;
    height: 48px;
    background-color: rgba(161, 83, 170, 0.2);
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    transition: background-color .25s;
    position: relative;
}

.options .search__button svg {
    fill: var(--color-primary);
}

.options .search__button:hover { background-color: rgba(161, 83, 170, 0.2) }
.options .search__button:hover svg { fill: var(--color-primary) }

.search-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    z-index: 999990;
}

.search__head {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    background-color: white;
    gap: 12px;
    position: relative;
}

.search__head .search__icon {
    position: absolute;
    right: 24px;
    width: 34px;
    height: 34px;
    opacity: 0.35;
    pointer-events: none;
}

.search__back {
    background: none;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search__head input {
    height: 50px;
    width: 100%;
    border-radius: 200px;
}

.search__list {
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow-y: auto;
}

.search__item {
    padding: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
    background-color: white;
    text-decoration: none;
    color: var(--color-dark);
}

.options .nav {
    display: flex;
    align-items: center;
    gap: 32px;
    /* Hidden on mobile */
    display: none;
    position: relative;
}

.options .profile {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.profile-menu {
    position: absolute;
    top: 45px;
    right: 0px;
}

.profile-menu__overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
}

.profile-menu__content {
    width: 215px;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 8px 0px;
    border-radius: 8px;
    position: relative;
    z-index: 9;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.profile-menu__item {
    padding: 12px 24px;
    cursor: pointer;
}

.profile-menu__item:last-child {
    color: #D45721;
}

.options .nav .link {
    color: var(--color-dark);
    text-decoration: none;
}

.options .nav .link.router-link-active {
    color: var(--color-primary);
    text-decoration: none;
}

.authentication-overlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.75);
}

.authentication-container {
    position: relative;
    z-index: 99999;
}

.authentication-modal {
    width: 300px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 0;
    top: 64px;
    z-index: 9999;
}

.authentication-modal__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.authentication-modal__content p {
    text-align: center;
}

.authentication-modal__close {
    text-decoration: underline;
    color: var(--color-primary);
    cursor: pointer;
}

.authentication-options {
    width: 200px;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 8px 0px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 0;
    top: 64px;
    z-index: 9999;
}

.athentication-item {
    padding: 12px 18px;
    text-decoration: none;
    color: var(--color-dark);
}

.athentication-item.red {
    color: #D45721;
}

.enter {
    width: 140px;
    max-width: 140px;
}

@media (min-width: 920px) {
    .edc-header .content { padding: 0px 24px }
    .logo { border-radius: 0px 0px 8px 8px; }
    .logo::before { border-radius: 0px 0px 8px 8px; }
    .options { gap: 24px }
    .options .nav { display: flex }
    .options .search__button { background-color: rgba(161, 83, 170, 0); width: 42px; height: 42px; }
    .options .search__button svg { fill: var(--color-dark) }
}
</style>