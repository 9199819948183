const subscribers = [];

class AuthModal {
    static openModal(type) {
        this.#send({ open: true, modal: type })
    }
    
    static closeModals() {
        this.#send({ open: false, modal: "login" })
    }

    static #send(data) {
        subscribers.forEach(subscriber => {
            subscriber(data)
        })
    }
    
    static subscribe(fn) {
        subscribers.push(fn)
    }
    
    static unsubscribe(fn) {
        const idx = subscribers.findIndex(item => item === fn)
        subscribers.splice(idx, 1)
    }
}

export {
    AuthModal
}