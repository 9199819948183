<template>
    <div class="login-provider">
        <p>Loading...</p>
    </div>
</template>

<script>
import { Auth } from '@/services/auth';
import { initializeApp } from 'firebase/app'
import { getAuth, signInWithRedirect, getRedirectResult, GoogleAuthProvider } from "firebase/auth";

export default {
    name: "edc-login-provider",
    mounted() {
        const firebaseConfig = {
            apiKey: "AIzaSyA5PuW4O9A0q46w2rN6yu0-oodmYTri5ug",
            authDomain: "enamoratedelcesar.firebaseapp.com",
            databaseURL: "https://enamoratedelcesar-default-rtdb.firebaseio.com",
            projectId: "enamoratedelcesar",
            storageBucket: "enamoratedelcesar.appspot.com",
            messagingSenderId: "291995958681",
            appId: "1:291995958681:web:db97fa48d887ebf9d22708",
            measurementId: "G-PV1BL6322J",
        };

        initializeApp(firebaseConfig);
        const provider = new GoogleAuthProvider();
        const auth = getAuth();

        getRedirectResult(auth)
        .then((result) => {
            if (result?.user != null) {
                result.user.getIdToken().then((token) => {
                    Auth.loginProvider(token).then(() => {
                        this.$router.push({ name: 'home' })
                    })
                })
            } else {
                signInWithRedirect(auth, provider)
                .then((result) => {
                    console.log(result)
                }).catch((error) => {
                    console.log(error)
                });
            }
        })
        
    }
}
</script>

<style>
.login-provider {
    padding: 24px;
}
</style>