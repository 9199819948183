<template>
    <div class="edc-stars">
        <div class="edc-stars__list">
            <img src="../assets/star.svg" 
            v-for="(star, idx) in getStars()" 
            :class="`edc-star ${star}`" 
            :key="star + idx"
            :style="{ width: `${star_size}px`, height: `${star_size}px` }"
            @click="starClicked(idx)" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'edc-stars',
    props: {
        editable: {
            type: Boolean,
            default: false
        },
        quantity: {
            type: String,
            default: "0.0",
        },
        star_size: {
            type: Number,
            required: false,
            default: 24
        }
    },
    data() {
        return {
            stars: 0
        }
    },
    emits: ['star_clicked'],
    methods: {
        getStars() {
            this.stars = parseInt(this.quantity)
            const arr = []

            for (let i = 1; i <= 5; i++) {
                if (i <= this.stars) {
                    arr.push('active')
                } else {
                    arr.push('')
                }
            }

            return arr;
        },
        starClicked(number) {
            if (this.editable) {
                this.stars = number + 1;
            }
            
            this.$emit('star_clicked', number + 1)
        }
    },
}
</script>

<style>
.edc-stars {
    display: flex;
}

.edc-stars__list {
    display: flex;
    gap: 4px;
}

.edc-star {
    filter: grayscale(1);
    opacity: 0.5;
}

.edc-stars__message {
    color: white;
    font-weight: bold;
    opacity: 0.5;
}

.edc-star.active {
    filter: grayscale(0);
    opacity: 1;
}
</style>