import { createApp } from 'vue'
import App from './App.vue'
import router from './routes'
// Splide
import '@splidejs/splide/css/core';
// Photoswipe
import 'photoswipe/style.css'
// Captcha
import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App);
app.use(router).mount('#app')
app.use(VueReCaptcha, { siteKey: '6LevwNUoAAAAAKW1XVt21z9XimBe-dUMiAqX4CNT' })