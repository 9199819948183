const MUNICIPIO_CODES = [
    {
        "id": 16,
        "name": "Aguachica",
        "code": "aguachica",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/AGUACHICA.png",
        "description": "Epicentro del comercio en el Sur del Cesar, Aguachica es la segunda ciudad más poblada del departamento del Cesar. Su cultura refleja la diversidad de migrantes costeños, santandereanos, tolimenses, entre otros, que la han ido poblando, lo que es evidente en sus tradiciones y gastronomía."
    },
    {
        "id": 25,
        "name": "Agustín Codazzi",
        "code": "agustin_codazzi",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/CODAZZI_COMPO.png",
        "description": "Esta región se distingue por su composición multicultural, gracias en parte a su ubicación como punto de encuentro de los pueblos indígenas yukpas, afrocolombianos y mestizos.  Además, la influencia de la ruta de los juglares, que atraviesa la Serranía del Perijá, se aprecia en todo el municipio.  La fusión de culturas alimenta una serie de celebraciones patronales en los barrios y corregimientos.  Además, cada mes de agosto se realiza el Festival de Música Vallenata en Guitarra"
    },
    {
        "id": 6,
        "name": "Astrea",
        "code": "astrea",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/ASTREA_COMPO.png",
        "description": "Municipio conocido en el pasado, como ruta obligatoria para el transporte de ganado a las playas del río Cesar. Actualmente, la región gira en torno a la ganadería; su renombrada producción de productos lácteos, donde el queso es el más representativo y para sus habitantes es conocido como el \"oro blanco\"."
    },
    {
        "id": 24,
        "name": "Becerril del Campo",
        "code": "becerril",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/BECERRIL_COMPO.png",
        "description": "Remontándose a la época colonial, este municipio se encuentra entre los más antiguos del departamento, habiendo sido moldeado por la cultura y tradiciones folclóricas vallenatas, así como por la importante influencia de la comunidad indígena Yukpa.  Esta cultura cobra especial protagonismo durante la Fiesta de la Paletilla, que tiene lugar del 2 al 6 de febrero, y comienza con la celebración de la Virgen de la Candelaria, patrona del municipio."
    },
    {
        "id": 3,
        "name": "Bosconia",
        "code": "bosconia",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/BOSCONIA_COMPO.png",
        "description": "La afluencia de personas y mercancías han transformado el municipio en un centro de oportunidades comerciales.  Entre los lugares de importancia turística se encuentran la Estación del Ferrocarril de Colombia, declarada Patrimonio Histórico, la Casa de los Cano, patrimonio de la ciudad, el Templo de San Juan Bosco, y el Árbol de la Paz ubicado en la plaza cívica. No dejes de probar el chicharron con yuca, plato emblematico de la ciudad."
    },
    {
        "id": 8,
        "name": "Chimichagua",
        "code": "chimichagua",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/CHIMICHAGUA_COMPO.png",
        "description": "Debido a su ubicación cerca de ríos y ciénagas, así como pastizales, Chimichagua se convirtió en un importante centro comercial durante la era colonial. Su paisaje emana un encanto difícil de resistir, siendo la deslumbrante Ciénaga de Zapatosa y sus encantadoras islas un atractivo turístico de primer orden. Su religión y se refleja en la fusión de tradiciones indígenas, españolas y africanas, donde las historias, mitos, leyendas y canciones son protagonistas."
    },
    {
        "id": 7,
        "name": "Chiriguaná",
        "code": "chiriguana",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/CHIRIGUANA_.png",
        "description": "Atravesada por personajes como el conquistador Jiménez de Quesada y el Libertador Simón Bolívar, esta tierra pertenecia al pueblo Chimila.  El tejido cultural de la región está imbuido de una mezcla de influencias indias, españolas y africanas, como es evidente en su identidad colectiva expresada a través de bailes y festividades.  Las Fiestas Patronales regionales de Nuestra Señora del Rosario de Chiquinquirá es su fiesta más representativa."
    },
    {
        "id": 9,
        "name": "Curumaní",
        "code": "curumani",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/ILUSTRACION_CURUMANI.png",
        "description": "Conocido por su riqueza arqueológica de la cultura Malibú, una etnia de la época prehispánica que se ubicaba entre el complejo cenagoso de Zapatosa y la Serranía del Perijá. Asimismo, su ubicación en el centro del departamento, logra integrar la cultura entre los “cachacos\" y \"costeños” sobre todo en su música y gastronomía. Esta diversidad hace que sea un municipio rico en paisajes, cultura y arqueología."
    },
    {
        "id": 4,
        "name": "El Copey",
        "code": "el_copei",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/EL_COPEY_COMPO.png",
        "description": "Villa del Cesar incrustada en la majestuosa Sierra Nevada de Santa Marta y el valle del rio Ariguaní, es famosa por sus balnearios naturales y presencia indígena de los pueblo Chimila y Arhuacos"
    },
    {
        "id": 1,
        "name": "El Paso",
        "code": "el_paso",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/EL_PASO.png",
        "description": "La fusión de las culturas indígena, española y africana es evidente en la vibrante cultura pasera. Los ritmos dinámicos de los bailes cantaos, la influencia afrocaribeña ha logrado integrar a su cultura una fuerte esencia, donde a ella se integra el vallenato. La cultura pasera es conocida por sus fuertes juglares, compositores y su gran festival \"mi pedazo de acordeón\"."
    },
    {
        "id": 15,
        "name": "Gamarra",
        "code": "gamarra",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/gamarra.png",
        "description": "Este es un municipio con mucha historia y tradición, donde lo más significativo ha llegado por el río Magdalena. Puertos, transporte ferroviario, cable aéreo, la tradición de la música de tambora... incluso por el río llegó la imagen de su patrona La Virgen de la Inmaculada Concepción."
    },
    {
        "id": 21,
        "name": "González",
        "code": "gonzalez",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/GONZALEZ.png",
        "description": "Territorio de montañas donde habitaron grupos de indígenas que luego serían parte de las encomiendas de la provincia de Ocaña. Su gente es muy religiosa, por lo que la Semana Santa es una de sus celebraciones más importantes, y sus patronos San Juan Crisóstomo y San Roque."
    },
    {
        "id": 14,
        "name": "La Gloria",
        "code": "la_gloria",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/LA_GLORIA.png",
        "description": "La Gloria está influenciado por dos regiones naturales que corresponden a \r\nel Valle del Río Magdalena y la Serranía del Perijá. El primero ha tenido una fuerte influencia en su cultura, ya que fue uno de los puertos más importantes de la región por su comercio, por lo que llegaron muchos migrantes. Su cercanía al río alimenta su cultura anfibia, que se manifiesta en su gastronomía, tradiciones, música, bailes y leyendas."
    },
    {
        "id": 23,
        "name": "La Jagua de Ibirico",
        "code": "la_jagua_de_ibirico",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/LA_JAGUA_COMPO.png",
        "description": "Ubicado en la base de la Serranía del Perijá, se encuentra La Jagua de Ibirico, Despensa agrícola y minera. Su cercanía a la Serranía permite que tenga diversidad de pisos térmicos, permitiendo generar producción de café, cacao y aguacate, entre otros. Dentro de sus celebraciones culturales se encuentra las fiestas patronales de San Miguel Arcángel y el Festival Folclórico Agrícola y Minero."
    },
    {
        "id": 11,
        "name": "La Paz",
        "code": "la_paz",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/LA_PAZ_COMPO.png",
        "description": "Los juglares vallenatos que recorren el municipio de La Paz son testimonio de la influencia de su cultura musical.  Las notas del acordeón de la Dinastía López, los versos de Alfonso \"poncho\" Cotes, las melodías de Escalona y las obras del reconocido Jorge Oñate reflejan el rico acervo musical del pueblo.  La Paz, también es famoso por producir las deliciosas almojábanas a base de queso fresco, leche, maíz molido, sal y azúcar.  Este manjar regional es considerado patrimonio cultural e inmateria"
    },
    {
        "id": 22,
        "name": "Manaure Balcón del Cesar",
        "code": "manaure",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/MANAURE_COMPO.png",
        "description": "La cercanía de Manaure Balcón del Cesar a la Serranía del Perijá, junto con su entorno natural distintivo, que cuenta con un ecosistema de bosque seco tropical que alberga fauna y aves endémicas, lo ha consolidado como un centro para el ecoturismo y la observación  del paisaje."
    },
    {
        "id": 10,
        "name": "Pailitas",
        "code": "pailitas",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/PAILITAS_COMPO.png",
        "description": "Es un municipio que gracias a las diversas migraciones desde el interior del país, los Santanderes y la región ribereña del Magdalena, ha integrado multiplicidad de tradiciones en su cultura, como la música, que va desde el Vallenato y Carranga hasta tambora tradicional. En la actualidad es conocido por sus ricos jugos ubicados sobre la vía nacional, sus lavanderas y paisajes naturales."
    },
    {
        "id": 13,
        "name": "Pelaya",
        "code": "pelaya",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/PELAYA.png",
        "description": "Pelaya es un municipio diverso, gracias a las migraciones de los santanderes y de la región ribereña del Magdalena. Esto se nota en su gastronomía donde destacan los platillos elaborados con pescado, por su Ciénaga de Sahaya, y maíz, como las arepas y la chicha de maíz, cereal del que es uno de los principales productores. También en su musica que va desde el Vallenato hasta la tambora tradicional, herencia Afro que se mantiene en sus corregimientos San Bernardo y Costilla."
    },
    {
        "id": 2,
        "name": "Pueblo Bello",
        "code": "pueblo_bello",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/ilustracion_pueblo_bello_M0DfbZL.png",
        "description": "El municipio cuenta con una representación excepcional de los cuatro pueblos indígenas de la Sierra Nevada de Santa Marta.  Ubicado a una altura aproximada de 2200 metros sobre el nivel del mar, también se encuentra aquí la puerta de entrada al pueblo indígena de Nabusimake, la capital espiritual del pueblo Arhuaco.  Rodeado por la Sierra Nevada de Santa Marta, Pueblo Bello ofrece amplias oportunidades para el cultivo de café, observación de aves y recorridos por las áreas de reserva natural"
    },
    {
        "id": 20,
        "name": "Río de Oro",
        "code": "rio_de_oro",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/RIO_DE_ORO.png",
        "description": "Pueblo devoto a la Virgen del Rosario de Río de Oro, a quién le deben su fundación y muchas historias. De Gente alegre y festiva, con su Festival de la Matanza del 'Tigre', en honor a la leyenda de la lavandera atacada por un jaguar, y sus Carnavales, llenos de máscaras y color. Aquí se conjugan la fe y la magia."
    },
    {
        "id": 18,
        "name": "San Alberto",
        "code": "san_alberto",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/SAN_ALBERTO.png",
        "description": "El primer municipio del Cesar, llegando desde el interior del país por la Troncal del Magdalena, es San Alberto. Esto lo hace un territorio diverso, con una cultura caribeña, pero también de santandereanos, tolimenses y del pacífico. Entre sus paisajes encontramos el Río San Alberto, quebradas y las zonas de cultivos de Palma de Aceite."
    },
    {
        "id": 5,
        "name": "San Diego",
        "code": "san_diego",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/SAN_DIEGO.png",
        "description": "Epicentro de la extinta cultura Tupe, San Diego se caracteriza por la amabilidad y fervor religioso de sus habitantes, los deliciosos bocados a base de maiz y la presencia de huellas prehispanicas y edificaciones patrimoniales."
    },
    {
        "id": 19,
        "name": "San Martín",
        "code": "san_martin",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/SAN_MARTIN.png",
        "description": "Con sus dos Eco-regiones 'Valle del Río Magdalena' y 'Serranía del Perijá', en San Martín encontramos diversidad de paisajes y fauna. Desde la ciénaga hasta la montaña con sus cascadas encontramos diversidad de historias y tradiciones, donde aún se retratan las culturas ancestrales y los migrantes del interior que llegaron a poblar estas tierras."
    },
    {
        "id": 12,
        "name": "Tamalameque",
        "code": "tamalameque",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/TAMALAMEQUE.png",
        "description": "Su historia se remonta a tiempos precolombinos, cuando en esta zona entre los ríos Magdalena y Cesar, y la Ciénaga de Zapatosa habitaban los Malibú. De ahí su nombre, Tamalameque, en honor a un cacique Malibú."
    },
    {
        "id": 17,
        "name": "Valledupar",
        "code": "valledupar",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/VALLEDUPAR_COMPO.png",
        "description": "Es la capital del departamento. Está ubicada al nororiente de la Costa Caribe, a orillas del río Guatapurí, en el valle del río Cesar formado por la Sierra Nevada de Santa Marta y la serranía del Perijá. Es ampliamente conocida por ser la cuna del vallenato y celebrar anualmente el Festival de la Leyenda Vallenata, este nace con el fin de hacer un realce a la música vallenata que es un ritmo fruto de la mezcla de culturas que se asentaron en la antigua “Provincia de Padilla”"
    }
]

const MUNICIPIO_CODES_ALL = [
    {
        "id": 16,
        "name": "Aguachica",
        "code": "aguachica",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/AGUACHICA.png",
        "description": "Epicentro del comercio en el Sur del Cesar, Aguachica es la segunda ciudad más poblada del departamento del Cesar. Su cultura refleja la diversidad de migrantes costeños, santandereanos, tolimenses, entre otros, que la han ido poblando, lo que es evidente en sus tradiciones y gastronomía."
    },
    {
        "id": 25,
        "name": "Agustín Codazzi",
        "code": "agustin_codazzi",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/CODAZZI_COMPO.png",
        "description": "Esta región se distingue por su composición multicultural, gracias en parte a su ubicación como punto de encuentro de los pueblos indígenas yukpas, afrocolombianos y mestizos.  Además, la influencia de la ruta de los juglares, que atraviesa la Serranía del Perijá, se aprecia en todo el municipio.  La fusión de culturas alimenta una serie de celebraciones patronales en los barrios y corregimientos.  Además, cada mes de agosto se realiza el Festival de Música Vallenata en Guitarra"
    },
    {
        "id": 6,
        "name": "Astrea",
        "code": "astrea",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/ASTREA_COMPO.png",
        "description": "Municipio conocido en el pasado, como ruta obligatoria para el transporte de ganado a las playas del río Cesar. Actualmente, la región gira en torno a la ganadería; su renombrada producción de productos lácteos, donde el queso es el más representativo y para sus habitantes es conocido como el \"oro blanco\"."
    },
    {
        "id": 24,
        "name": "Becerril del Campo",
        "code": "becerril",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/BECERRIL_COMPO.png",
        "description": "Remontándose a la época colonial, este municipio se encuentra entre los más antiguos del departamento, habiendo sido moldeado por la cultura y tradiciones folclóricas vallenatas, así como por la importante influencia de la comunidad indígena Yukpa.  Esta cultura cobra especial protagonismo durante la Fiesta de la Paletilla, que tiene lugar del 2 al 6 de febrero, y comienza con la celebración de la Virgen de la Candelaria, patrona del municipio."
    },
    {
        "id": 3,
        "name": "Bosconia",
        "code": "bosconia",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/BOSCONIA_COMPO.png",
        "description": "La afluencia de personas y mercancías han transformado el municipio en un centro de oportunidades comerciales.  Entre los lugares de importancia turística se encuentran la Estación del Ferrocarril de Colombia, declarada Patrimonio Histórico, la Casa de los Cano, patrimonio de la ciudad, el Templo de San Juan Bosco, y el Árbol de la Paz ubicado en la plaza cívica. No dejes de probar el chicharron con yuca, plato emblematico de la ciudad."
    },
    {
        "id": 8,
        "name": "Chimichagua",
        "code": "chimichagua",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/CHIMICHAGUA_COMPO.png",
        "description": "Debido a su ubicación cerca de ríos y ciénagas, así como pastizales, Chimichagua se convirtió en un importante centro comercial durante la era colonial. Su paisaje emana un encanto difícil de resistir, siendo la deslumbrante Ciénaga de Zapatosa y sus encantadoras islas un atractivo turístico de primer orden. Su religión y se refleja en la fusión de tradiciones indígenas, españolas y africanas, donde las historias, mitos, leyendas y canciones son protagonistas."
    },
    {
        "id": 7,
        "name": "Chiriguaná",
        "code": "chiriguana",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/CHIRIGUANA_.png",
        "description": "Atravesada por personajes como el conquistador Jiménez de Quesada y el Libertador Simón Bolívar, esta tierra pertenecia al pueblo Chimila.  El tejido cultural de la región está imbuido de una mezcla de influencias indias, españolas y africanas, como es evidente en su identidad colectiva expresada a través de bailes y festividades.  Las Fiestas Patronales regionales de Nuestra Señora del Rosario de Chiquinquirá es su fiesta más representativa."
    },
    {
        "id": 9,
        "name": "Curumaní",
        "code": "curumani",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/ILUSTRACION_CURUMANI.png",
        "description": "Conocido por su riqueza arqueológica de la cultura Malibú, una etnia de la época prehispánica que se ubicaba entre el complejo cenagoso de Zapatosa y la Serranía del Perijá. Asimismo, su ubicación en el centro del departamento, logra integrar la cultura entre los “cachacos\" y \"costeños” sobre todo en su música y gastronomía. Esta diversidad hace que sea un municipio rico en paisajes, cultura y arqueología."
    },
    {
        "id": 4,
        "name": "El Copey",
        "code": "el_copei",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/EL_COPEY_COMPO.png",
        "description": "Villa del Cesar incrustada en la majestuosa Sierra Nevada de Santa Marta y el valle del rio Ariguaní, es famosa por sus balnearios naturales y presencia indígena de los pueblo Chimila y Arhuacos"
    },
    {
        "id": 1,
        "name": "El Paso",
        "code": "el_paso",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/EL_PASO.png",
        "description": "La fusión de las culturas indígena, española y africana es evidente en la vibrante cultura pasera. Los ritmos dinámicos de los bailes cantaos, la influencia afrocaribeña ha logrado integrar a su cultura una fuerte esencia, donde a ella se integra el vallenato. La cultura pasera es conocida por sus fuertes juglares, compositores y su gran festival \"mi pedazo de acordeón\"."
    },
    {
        "id": 15,
        "name": "Gamarra",
        "code": "gamarra",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/gamarra.png",
        "description": "Este es un municipio con mucha historia y tradición, donde lo más significativo ha llegado por el río Magdalena. Puertos, transporte ferroviario, cable aéreo, la tradición de la música de tambora... incluso por el río llegó la imagen de su patrona La Virgen de la Inmaculada Concepción."
    },
    {
        "id": 21,
        "name": "González",
        "code": "gonzalez",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/GONZALEZ.png",
        "description": "Territorio de montañas donde habitaron grupos de indígenas que luego serían parte de las encomiendas de la provincia de Ocaña. Su gente es muy religiosa, por lo que la Semana Santa es una de sus celebraciones más importantes, y sus patronos San Juan Crisóstomo y San Roque."
    },
    {
        "id": 14,
        "name": "La Gloria",
        "code": "la_gloria",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/LA_GLORIA.png",
        "description": "La Gloria está influenciado por dos regiones naturales que corresponden a \r\nel Valle del Río Magdalena y la Serranía del Perijá. El primero ha tenido una fuerte influencia en su cultura, ya que fue uno de los puertos más importantes de la región por su comercio, por lo que llegaron muchos migrantes. Su cercanía al río alimenta su cultura anfibia, que se manifiesta en su gastronomía, tradiciones, música, bailes y leyendas."
    },
    {
        "id": 23,
        "name": "La Jagua de Ibirico",
        "code": "la_jagua_de_ibirico",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/LA_JAGUA_COMPO.png",
        "description": "Ubicado en la base de la Serranía del Perijá, se encuentra La Jagua de Ibirico, Despensa agrícola y minera. Su cercanía a la Serranía permite que tenga diversidad de pisos térmicos, permitiendo generar producción de café, cacao y aguacate, entre otros. Dentro de sus celebraciones culturales se encuentra las fiestas patronales de San Miguel Arcángel y el Festival Folclórico Agrícola y Minero."
    },
    {
        "id": 11,
        "name": "La Paz",
        "code": "la_paz",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/LA_PAZ_COMPO.png",
        "description": "Los juglares vallenatos que recorren el municipio de La Paz son testimonio de la influencia de su cultura musical.  Las notas del acordeón de la Dinastía López, los versos de Alfonso \"poncho\" Cotes, las melodías de Escalona y las obras del reconocido Jorge Oñate reflejan el rico acervo musical del pueblo.  La Paz, también es famoso por producir las deliciosas almojábanas a base de queso fresco, leche, maíz molido, sal y azúcar.  Este manjar regional es considerado patrimonio cultural e inmateria"
    },
    {
        "id": 22,
        "name": "Manaure Balcón del Cesar",
        "code": "manaure",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/MANAURE_COMPO.png",
        "description": "La cercanía de Manaure Balcón del Cesar a la Serranía del Perijá, junto con su entorno natural distintivo, que cuenta con un ecosistema de bosque seco tropical que alberga fauna y aves endémicas, lo ha consolidado como un centro para el ecoturismo y la observación  del paisaje."
    },
    {
        "id": 10,
        "name": "Pailitas",
        "code": "pailitas",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/PAILITAS_COMPO.png",
        "description": "Es un municipio que gracias a las diversas migraciones desde el interior del país, los Santanderes y la región ribereña del Magdalena, ha integrado multiplicidad de tradiciones en su cultura, como la música, que va desde el Vallenato y Carranga hasta tambora tradicional. En la actualidad es conocido por sus ricos jugos ubicados sobre la vía nacional, sus lavanderas y paisajes naturales."
    },
    {
        "id": 13,
        "name": "Pelaya",
        "code": "pelaya",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/PELAYA.png",
        "description": "Pelaya es un municipio diverso, gracias a las migraciones de los santanderes y de la región ribereña del Magdalena. Esto se nota en su gastronomía donde destacan los platillos elaborados con pescado, por su Ciénaga de Sahaya, y maíz, como las arepas y la chicha de maíz, cereal del que es uno de los principales productores. También en su musica que va desde el Vallenato hasta la tambora tradicional, herencia Afro que se mantiene en sus corregimientos San Bernardo y Costilla."
    },
    {
        "id": 2,
        "name": "Pueblo Bello",
        "code": "pueblo_bello",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/ilustracion_pueblo_bello_M0DfbZL.png",
        "description": "El municipio cuenta con una representación excepcional de los cuatro pueblos indígenas de la Sierra Nevada de Santa Marta.  Ubicado a una altura aproximada de 2200 metros sobre el nivel del mar, también se encuentra aquí la puerta de entrada al pueblo indígena de Nabusimake, la capital espiritual del pueblo Arhuaco.  Rodeado por la Sierra Nevada de Santa Marta, Pueblo Bello ofrece amplias oportunidades para el cultivo de café, observación de aves y recorridos por las áreas de reserva natural"
    },
    {
        "id": 20,
        "name": "Río de Oro",
        "code": "rio_de_oro",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/RIO_DE_ORO.png",
        "description": "Pueblo devoto a la Virgen del Rosario de Río de Oro, a quién le deben su fundación y muchas historias. De Gente alegre y festiva, con su Festival de la Matanza del 'Tigre', en honor a la leyenda de la lavandera atacada por un jaguar, y sus Carnavales, llenos de máscaras y color. Aquí se conjugan la fe y la magia."
    },
    {
        "id": 18,
        "name": "San Alberto",
        "code": "san_alberto",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/SAN_ALBERTO.png",
        "description": "El primer municipio del Cesar, llegando desde el interior del país por la Troncal del Magdalena, es San Alberto. Esto lo hace un territorio diverso, con una cultura caribeña, pero también de santandereanos, tolimenses y del pacífico. Entre sus paisajes encontramos el Río San Alberto, quebradas y las zonas de cultivos de Palma de Aceite."
    },
    {
        "id": 5,
        "name": "San Diego",
        "code": "san_diego",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/SAN_DIEGO.png",
        "description": "Epicentro de la extinta cultura Tupe, San Diego se caracteriza por la amabilidad y fervor religioso de sus habitantes, los deliciosos bocados a base de maiz y la presencia de huellas prehispanicas y edificaciones patrimoniales."
    },
    {
        "id": 19,
        "name": "San Martín",
        "code": "san_martin",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/SAN_MARTIN.png",
        "description": "Con sus dos Eco-regiones 'Valle del Río Magdalena' y 'Serranía del Perijá', en San Martín encontramos diversidad de paisajes y fauna. Desde la ciénaga hasta la montaña con sus cascadas encontramos diversidad de historias y tradiciones, donde aún se retratan las culturas ancestrales y los migrantes del interior que llegaron a poblar estas tierras."
    },
    {
        "id": 12,
        "name": "Tamalameque",
        "code": "tamalameque",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/TAMALAMEQUE.png",
        "description": "Su historia se remonta a tiempos precolombinos, cuando en esta zona entre los ríos Magdalena y Cesar, y la Ciénaga de Zapatosa habitaban los Malibú. De ahí su nombre, Tamalameque, en honor a un cacique Malibú."
    },
    {
        "id": 17,
        "name": "Valledupar",
        "code": "valledupar",
        "image": "https://enamorate-del-cesar-web-testing.s3.amazonaws.com/assets/media/illustrations/VALLEDUPAR_COMPO.png",
        "description": "Es la capital del departamento. Está ubicada al nororiente de la Costa Caribe, a orillas del río Guatapurí, en el valle del río Cesar formado por la Sierra Nevada de Santa Marta y la serranía del Perijá. Es ampliamente conocida por ser la cuna del vallenato y celebrar anualmente el Festival de la Leyenda Vallenata, este nace con el fin de hacer un realce a la música vallenata que es un ritmo fruto de la mezcla de culturas que se asentaron en la antigua “Provincia de Padilla”"
    }
]

MUNICIPIO_CODES_ALL.unshift({
    "id": 0,
    "name": "TODOS",
    "code": "all",
    "image": null,
    "description": "Todos los municipios"
})

export {
    MUNICIPIO_CODES,
    MUNICIPIO_CODES_ALL
}