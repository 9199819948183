<template>
    <div :class="`edc-spinner ${type} ${show ? 'visible' : ''}`">
        <div class="edc-spinner__overlay"></div>
        <div class="edc-spinner__content">
            <div class="edc-spinner__logo">
                <img src="../assets/logo.png" />
            </div>
            <div class="edc-spinner__icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" >
                    <path d="M10 3.5C8.27609 3.5 6.62279 4.18482 5.40381 5.40381C4.18482 6.6228 3.5 8.2761 3.5 10C3.5 10.1989 3.42098 10.3897 3.28033 10.5303C3.13968 10.671 2.94891 10.75 2.75 10.75C2.55109 10.75 2.36032 10.671 2.21967 10.5303C2.07902 10.3897 2 10.1989 2 10C2 8.41775 2.46919 6.87103 3.34824 5.55544C4.22729 4.23985 5.47672 3.21447 6.93853 2.60897C8.40034 2.00347 10.0089 1.84504 11.5607 2.15372C13.1126 2.4624 14.538 3.22433 15.6569 4.34315C16.7757 5.46197 17.5376 6.88743 17.8463 8.43928C18.155 9.99113 17.9965 11.5997 17.391 13.0615C16.7855 14.5233 15.7602 15.7727 14.4446 16.6518C13.129 17.5308 11.5823 18 10 18C9.80109 18 9.61032 17.921 9.46967 17.7803C9.32902 17.6397 9.25 17.4489 9.25 17.25C9.25 17.0511 9.32902 16.8603 9.46967 16.7197C9.61032 16.579 9.80109 16.5 10 16.5C11.7239 16.5 13.3772 15.8152 14.5962 14.5962C15.8152 13.3772 16.5 11.7239 16.5 10C16.5 8.2761 15.8152 6.6228 14.5962 5.40381C13.3772 4.18482 11.7239 3.5 10 3.5Z" />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "edc-spinner",
    props: {
        type: {
            default: false,
            validator(value) {
                return ['fixed', 'absolute', 'inline'].includes(value)
            }
        },
        show: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style>
.edc-spinner {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0px 40px;

    transition: all .25s;
    opacity: 0;
    pointer-events: none;
}

.edc-spinner.visible {
    min-height: 200px;
    opacity: 1;
    pointer-events: all;
}

.edc-spinner.fixed {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
}

.edc-spinner.absolute {
    position: absolute;
}

.edc-spinner.absolute .edc-spinner__logo {
    display: none;
}

.edc-spinner.inline {
    height: 0px;
    width: 100%;
    position: relative;
}

.edc-spinner.inline .edc-spinner__logo {
    display: none;
}

.edc-spinner__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--color-light);
}

.edc-spinner.fixed .edc-spinner__overlay {
    background-color: var(--color-light);
}

.edc-spinner__content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
}

.edc-spinner__logo {
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edc-spinner__logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: brightness(0);
}

.edc-spinner__icon {
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
}

.edc-spinner__icon svg {
    fill: black;
    width: 100%;
    height: 100%;
    animation-name: Spinner;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes Spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>