<template>
    <div class="e-viewer">
        <a class="e-viewer__back" @click="$router.back()">
            <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" >
                <path d="M28.3336 15.5834V18.4167H11.3336L19.1252 26.2084L17.1136 28.22L5.89355 17L17.1136 5.78003L19.1252 7.7917L11.3336 15.5834H28.3336Z" />
            </svg>
        </a>
        <div class="e-viewer__view" v-if="info.type === 'AR' || info.type === 'OV'">
            <iframe :src="info.iframe" frameborder="0" width="540" height="970" allowfullscreen="true"></iframe>
        </div>
        <div class="e-viewer__view" v-if="info.type === 'V3' || info.type === 'CS'" v-html="info.iframe"></div>
    </div>
</template>

<script>
import { ExperiencesAPI } from '../services/experiences'

export default {
    name: "edc-experience-view",
    data() {
        return {
            info: {}
        }
    },
    mounted() {
        const experienceID = parseInt(this.$route.params.id)

        ExperiencesAPI.getExperience(experienceID)
        .then(data => {
            this.info = data;
        })
    }
}
</script>

<style>
.e-viewer {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-color: black;
}


.e-viewer::before {
    content: '';
    height: 150px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.00) 100%);
}


.e-viewer__view {
    width: 100%;
    height: 100%;
}

iframe {
    width: 100%;
    height: 100%;
    background-color: black;
}

.e-viewer__back {
    display: block;
    height: 54px;
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    gap: 8px;
    color: var(--color-light);
    fill: var(--color-dark);
    font-weight: 600;
    cursor: pointer;
    position: absolute;
    left: 16px;
    top: 16px;
    padding: 8px;
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 200px;
}

.e-viewer__back svg {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
</style>