<template>
    <EDCHeader />
    <div class="businesses-hero">
        <div class="businesses-hero__bk"><img src="../assets/Cultivos-de-cafe.jpg" /></div>
        <div class="businesses-hero__content">
            <h1 class="businesses-hero__title">Negocios</h1>
            <p class="businesses-hero__description">Explora restaurantes, hoteles, transportes y tours dentro del Cesar</p>
        </div>
    </div>
    <div class="businesses-body">
        <div class="businesses-body__left">
            <h3 class="businesses-body__left-title">Municipio:</h3>
            <div class="businesses-municipio__list">
                <p
                    @click="onFilterMunicipio(municipio.code)"
                    class="businesses-municipio" 
                    :class="[filtered == municipio.code ? 'active' : '']" 
                    v-for="municipio in municipio_list"
                    :key="municipio.code">
                    {{ municipio.name }}
                </p>
            </div>
        </div>
        <div class="businesses-body__content">
            <div class="edc-section-head">
                <h1 class="edc-section-head__title">Servicios turísticos en el Cesar</h1>
                <p class="edc-section-head__description">
                    Prueba las delicias del Cesar, hospédate en sus pueblos y disfruta de todos los paisajes que puede ofrecerte. Llévate los recuerdos en fotografías o artesanías locales, hay una extensa oferta esperando en cada municipio.
                </p>
            </div>
            <div class="edc-selector">
                <select name="municipio_selector" id="municipio_selector" @change="onFilterMunicipio()">
                    <option :value="municipio.code" :key="municipio.code" v-for="municipio in municipio_list">{{ municipio.name }}</option>
                </select>
            </div>
            <EDCServiceTypeFilter @filter_change="onFilterApplied" class="businesses-filter" />
            <EDCEmpty message="No se encontraron negocios con estos filtros" v-show="!businesses.length && !spinner" />
            <EDCSpinner type="inline" :show="spinner" />
            <div class="businesses-list" v-show="businesses.length && !spinner">
                <EDCBusiness 
                v-for="(business) in businesses"
                :key="business.pk"
                :id="business.pk"
                :type="business.type" 
                :name="business.name" 
                :stars="parseInt(business.rating)" 
                :image="business.image" />
            </div>
            <EDCButton label="Cargar más" v-show="next && !spinner" @click="onNextButton()" />
        </div>
    </div>
    <EDCFooter />
    <EDCMobileNav />
</template>

<script>
import EDCHeader from '../components/Header.vue';
import EDCFooter from '../components/Footer.vue';
import EDCMobileNav from '../components/MobileNav.vue';
import EDCBusiness from '../components/Business.vue';
import EDCButton from '../components/Button.vue';
import EDCServiceTypeFilter from '../components/ServiceTypeFilter.vue';
import EDCEmpty from '../components/Empty.vue';
import EDCSpinner from '../components/Spinner.vue';

import { MUNICIPIO_CODES_ALL } from '../scripts/municipio-codes';
import { BusinessesAPI } from '@/services/businesses';

export default {
    name: "edc-businesses-view",
    components: {
        EDCHeader,
        EDCFooter,
        EDCMobileNav,
        EDCBusiness,
        EDCButton,
        EDCServiceTypeFilter,
        EDCEmpty,
        EDCSpinner
    },
    data() {
        return {
            filtered: 'all',
            filter_type: 'RES',
            businesses: [],
            next: null,
            municipio_list: MUNICIPIO_CODES_ALL,
            spinner: false
        }
    },
    mounted() {
        this.getData(this.filtered, this.filter_type)
    },
    methods: {
        getData(municipio, type) {
            this.filtered = municipio;
            this.filter_type = type;
            
            let curr_municipio = this.filtered;
            if (curr_municipio === "all") curr_municipio = null
            
            this.showSpinner(true)
            BusinessesAPI.businessesFiltered(curr_municipio, this.filter_type).then(data => {
                this.showSpinner(false)
                this.businesses = data.results;
                this.next = data.next;
            })
        },
        onFilterMunicipio(municipio_code) {
            const selector = document.querySelector("#municipio_selector")
            let value = this.filtered
            
            if(!municipio_code) {
                value = selector.value;
            } else {
                value = municipio_code;
                selector.value = this.filtered;
            }

            this.getData(value, this.filter_type) 
        },
        onFilterApplied(type) {
            const types = {
                PLA: "RES",
                HOS: "HOS",
                TRA: "TRA",
                TOU: "TOU"
            }

            this.getData(this.filtered, types[type])
        },
        onNextButton() {
            fetch(this.next)
            .then(res => res.json())
            .then(data => {
                const results = data.data.results;

                if (results) {
                    this.next = data.data.next;

                    results.forEach(business => {
                        this.businesses.push(business)
                    })
                }
            })
        },
        showSpinner(show) {
            this.spinner = show
        }
    }
}
</script>

<style>
/* HERO */
.businesses-hero {
    width: 100%;
    height: 320px;
    overflow: hidden;
    position: relative;
}

.businesses-hero__bk {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.businesses-hero__bk::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .65);
}

.businesses-hero__bk img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.businesses-hero__content {
    max-width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 0px 40px;
    margin: 0 auto;
}

.businesses-hero__title,
.businesses-hero__description {
    text-align: center;
    color: white;
}

.businesses-hero__title {
    font-size: 2.25rem;
}

.businesses-hero__description {
    font-size: 1.2rem;
}

/* BODY */
.businesses-body {
    width: 100%;
    max-width: 1000px;
    padding: 24px;
    margin: 0px auto;
    margin-bottom: 40px;
    display: flex;
    gap: 24px;
}

.businesses-body__left {
    min-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    /* Hide on mobile */
    display: none;
}

.businesses-body__left-title {
    font-size: 1.5rem;
}

.businesses-municipio__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.businesses-municipio {
    text-decoration: none;
    color: var(--color-dark);
    cursor: pointer;
}

.businesses-municipio.active {
    color: var(--color-primary);
    font-weight: bold;
}

.businesses-body__content {
    width: 100%;
}

.businesses-body__content .edc-section-head {
    margin-bottom: 24px;
}

.businesses-filter {
    margin: 24px 0px;
}

.businesses-body button {
    max-width: 250px;
    margin: 0 auto;
    margin-top: 24px;
}

.businesses-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
    gap: 16px;
}

@media (min-width: 800px) {
    .businesses-body__left { display: flex }
    .businesses-body__content .edc-selector { display: none }
    .businesses-filter { margin-top: 0px }
}
</style>