import { createRouter, createWebHashHistory } from 'vue-router'

import LoginProvider from './views/LoginProvider.vue';
import Home from './views/Home.vue'
import Municipio from './views/Municipio.vue'
import MunicipioBusinesses from './views/MunicipioBusinesses.vue'
import Experiences from './views/Experiences.vue'
import ExperienceView from './views/ExperienceView.vue'
import Businesses from './views/Businesses.vue'
import BusinessDetails from './views/BusinessDetails.vue'
import News from './views/News.vue'
import NewDetails from './views/NewDetails.vue'
import About from './views/About.vue';
import Contact from './views/Contact.vue';
import Terms from './views/Terms.vue';
import Account from './views/Account.vue';
import RecoverPass from './views/RecoverPass.vue'
import ChangePass from './views/ChangePass.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/login-provider',
        name: 'login-provider',
        component: LoginProvider
    },
    {
        path: '/municipio/:id',
        children: [
            { path: "", name: 'municipio', component: Municipio },
            { path: "negocios", name: 'municipio-businesses', component: MunicipioBusinesses }
        ]
    },
    {
        path: '/play',
        children: [
            { path: '', name: 'play', component: Experiences },
            { path: ':id', name: 'play-viewer', component: ExperienceView }
        ]
    },
    {
        path: '/negocios',
        children: [
            { path: '', name: 'businesses', component: Businesses },
            { path: ':id', name: 'business-details', component: BusinessDetails }
        ]
    },
    {
        path: '/noticias',
        children: [
            { path: '', component: News, name: 'news' },
            { path: ':id', component: NewDetails, name: 'new-details' }
        ]
    },
    {
        path: '/cuenta',
        name: 'account',
        component: Account
    },
    {
        path: '/acerca-de',
        name: 'about',
        component: About
    },
    {
        path: '/contacto',
        name: 'contact',
        component: Contact
    },
    {
        path: '/terminos-y-condiciones',
        name: 'terms',
        component: Terms
    },
    {
        path: '/recuperar-contarsena',
        name: 'recover-pass',
        component: RecoverPass
    },
    {
        path: '/cambiar-contrasena',
        name: 'change-pass',
        component: ChangePass
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    next()
})

export default router
