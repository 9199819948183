import { API } from './_API';

class ExperiencesAPI {
    static async allExperiences() {
        return await fetch(`${API}/experiences/?page_size=12&format=json`)
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')
            
            return data.data
        })
    }

    /** @param {string} identifier unique identifier of the municipio */
    static async experiencesFilteredByMunicipio(identifier) {
        return await fetch(`${API}/experiences/?municipality=${identifier}&format=json`)
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')

            return data.data
        })
    }

    static async experiencesHome() {
        return await fetch(`${API}/municipios/random?page_size=5`)
        .then(res => res.json())
        .then(data => {
            return data.data?.results
        })
    }

    /** @param {Number} id identifier or experience */
    static async getExperience(id) {
        return await fetch(`${API}/experiences/${id}`)
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')

            return data.data
        })
    }
}

export {
    ExperiencesAPI
}