import { API } from './_API';

class NewsAPI {
    /** @param {number} id business ID */
    static async getNew(id) {
        return await fetch(`${API}/notices/${id}?format=json`)
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')

            return data.data
        })
    }

    static async allNews() {
        return await fetch(`${API}/notices/?page_size=12&format=json`)
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')
            
            return data.data
        })
    }

    /** @param {string} identifier unique identifier of the municipio */
    static async newsFilteredByMunicipio(identifier) {
        return await fetch(`${API}/notices/?municipality=${identifier}&format=json`)
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')

            return data.data
        })
    }
}

export {
    NewsAPI
}