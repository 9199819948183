<template>
    <div class="terms__content">
        <h1 class="terms__title">Términos y condiciones.</h1>
        <div class="terms__text">
            Blazing Soft S.A.S., ha diseñado el Portal Web https://enamoratedelcesar.co; el cual facilita a los usuarios el acceso a la información relativa al proyecto de Enamórate del Cesar. Así mismo, Blazing Soft S.A.S. no se hace responsable por el uso indebido de la información suministrada a través de este Portal Web.
            <br><br>
            La información de esta página web podrá ser utilizada teniendo en cuenta las normas que rigen y protegen el derecho a la propiedad intelectual, es decir los derechos sobre la propiedad de la información. Blazing Soft S.A.S. no se responsabiliza por el uso e interpretación realizada por terceros respecto de la información de esta página web.
            <br><br>
            Cualquier referencia a textos contenidos en los documentos deben incluir frases completas y hacer referencia a la fuente e indicar a la página de Enamórate del Cesar como su autor.
            <br><br>
            Este portal web contiene links de acceso a páginas externas sobre las que Blazing Soft S.A.S. no ejerce control alguno y respecto de las cuales no tiene responsabilidad, de tal forma que el contenido de esos enlaces de acceso será exclusiva responsabilidad de las entidades respectivas o de sus administradores.
            <br><br>
            Blazing Soft S.A.S. no se hace responsable de ningún daño o perjuicio que se derive del acceso a este portal o del uso de la información o aplicaciones en él contenido. Aceptación de los términos de uso. Blazing Soft S.A.S. solicita al visitante y al usuario de esta página web, leer estas condiciones y la política de privacidad, antes de iniciar su exploración o utilización.
            <br><br>
            Blazing Soft S.A.S. se reserva el derecho de actualizar y modificar en cualquier momento y de cualquier forma, de manera unilateral y sin previo aviso, las presentes condiciones de uso y los contenidos de esta página web.
        </div>
    </div>
</template>
  
<script>
export default {
    name: "edc-about-view"
}
</script>
  
<style>
.terms__content {
    width: 100%;
    max-width: 1000px;
    padding: 0px 24px;
    margin: 40px auto;
}

.terms__title {
    width: 100%;
    line-height: 110%;
    margin-bottom: 16px;
}
</style>