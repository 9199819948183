<template>
    <div class="b-details__schedule-item">
        <p class="day">{{ day }}:</p>
        <div class="times" v-show="open_24 === false && close_24 === false">
            <p class="time">{{ open_1 }} - {{ close_1 }}</p>
            <p class="time">{{ open_2 }} - {{ close_2 }}</p>
        </div>
        <p class="time" v-show="open_24 && !close_24">Abierto 24h</p>
        <p class="time" v-show="close_24">Cerrado</p>
    </div>
</template>

<script>
export default {
    name: "edc-schedule-item",
    props: {
        day: { type: String, required: true },
        open_1:   { type: String }, 
        open_2:   { type: String },
        close_1:  { type: String },
        close_2:  { type: String },
        open_24:  { type: Boolean },
        close_24: { type: Boolean }
    }
}
</script>

<style>
.b-details__schedule-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    padding: 6px 0px;
    font-size: 16px;
    color: var(--color-dark);
}

.b-details__schedule-item .times {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.b-details__schedule-item .day {
    font-weight: bold;
}

.b-details__schedule-item .time {
    font-size: 0.9rem;
}
</style>