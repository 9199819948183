import { Auth } from './auth';
import { API } from './_API';

class BusinessesAPI {
    /** @param {number} id business ID */
    static async getBusiness(id) {
        return await fetch(`${API}/business/${id}?format=json`)
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')

            return data.data
        })
    }

    static async allBusinesses() {
        return await fetch(`${API}/business/?page_size=12&format=json`)
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')
            
            return data.data
        })
    }

    /** 
     * @param {String} identifier unique identifier of the municipio
     * @param {("RES" | "TRA" | "HOS" | "TOU")} type type of business: RES=Restaurant, TRA=Transport, HOS=Hotel, TOU=Tour
     */
    static async businessesFiltered(identifier, type) {
        const params = new URLSearchParams()

        if (identifier) params.append('municipality', identifier)
        if (type) params.append('type', type)

        return await fetch(`${API}/business?${params}`)
        .then(res => res.json())
        .then(data => {
            return data.data
        })
    }

    /** 
     * @param {number} id business ID 
     * @param {("PLA" | "TRA" | "HOS" | "TOU")} type type of service: PLA="Food", TRA="Transport", HOS="Hotel", TOU="Tour"
     */
    static async getServices(id, type) {
        return await fetch(`${API}/business/${id}/service_items/?type=${type}`)
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')
            
            return data.data;
        })
    }

    /** @param {number} id service ID */
    static async getServiceDetails(id) {
        return await fetch(`${API}/item_business_service/${id}/`)
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')
            
            return data.data;
        })
    }

    /** @param {number} id business ID */
    static async getReviews(id) {
        let headers = {}

        if (Auth.getToken()) {
            headers = {
                Authorization: `Token ${Auth.getToken()}`
            }
        }

        return await fetch(`${API}/rating_comment?business=${id}&format=json`, { headers })
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')

            return data.data
        })
    }

    /** @param {number} id business ID */
    static async sendReview(id) {
        return await fetch(`${API}/rating_comment?business=${id}&format=json`, {
            method: "POST",
            headers: {
                Authorization: `Token ${Auth.getToken()}` 
            }
        })
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')

            return data.data
        })
    }
}

export {
    BusinessesAPI
}