<template>
    <EDCHeader />
    <div class="exps-hero">
        <div class="exps-hero__bk"><img src="../assets/Balneario-rio-manaure.jpg" /></div>
        <div class="exps-hero__content">
            <h1 class="exps-hero__title">Dale Play</h1>
            <p class="exps-hero__description">¡Disfruta de las experiencias interactivas que tenemos para ti!</p>
        </div>
    </div>
    <div class="exps-body">
        <div class="exps-body__left">
            <h3 class="exps-body__left-title">Municipio:</h3>
            <div class="exps-municipio__list">
                <p
                    @click="onFilterMunicipio(municipio.code)"
                    class="exps-municipio" 
                    :class="[filtered == municipio.code ? 'active' : '']" 
                    v-for="municipio in municipio_list"
                    :key="municipio.code">
                    {{ municipio.name }}
                </p>
            </div>
        </div>
        <div class="exps-body__content">
            <div class="edc-section-head">
                <h1 class="edc-section-head__title">Experiencias digitales</h1>
                <p class="edc-section-head__description">
                    A continuación, podrás encontrar diferentes experiencias interactivas basadas en cada municipio del Cesar:
                </p>
                <br>
                <ul>
                    <li><strong>OVA:</strong> Aprende sobre los sitios turísticos a través de videos y actividades.</li>
                    <li><strong>Videos 360°:</strong> Conoce los sitios turísticos como si estuvieras en tiempo real en un espacio. </li>
                    <li><strong>Cápsulas de tiempo:</strong> Ve videos cortos que narran historias de un municipio.</li>
                    <li><strong>Experiencias en realidad aumentada:</strong> Otra manera divertida de vivir la realidad y aprender sobre patrimonio y cultura.</li>
                </ul> 
            </div>
            <div class="edc-selector">
                <select name="municipio_selector" id="municipio_selector" @change="onFilterMunicipio()">
                    <option :value="municipio.code" :key="municipio.code" v-for="municipio in municipio_list">{{ municipio.name }}</option>
                </select>
            </div>
            <div class="exps-list">
                <EDCExperience 
                v-for="exp in experiences"
                :key="exp.id"
                :id="exp.id"
                :municipio="exp.municipality"
                :image="exp.thumbnail" 
                :type="exp.type" 
                :name="exp.name" />
            </div>
            <EDCButton label="Cargar más" v-show="this.next" @click="onNextButton()"/>
        </div>
    </div>
    <EDCFooter />
    <EDCMobileNav />
</template>

<script>
import EDCHeader from '../components/Header.vue';
import EDCFooter from '../components/Footer.vue';
import EDCMobileNav from '../components/MobileNav.vue';
import EDCExperience from '../components/Experience.vue';
import EDCButton from '../components/Button.vue';

import { MUNICIPIO_CODES_ALL } from '../scripts/municipio-codes';
import { ExperiencesAPI } from '@/services/experiences';
import { Spinner } from '../scripts/open-spinner';

export default {
    name: "edc-experiences-view",
    components: {
        EDCHeader,
        EDCFooter,
        EDCMobileNav,
        EDCExperience,
        EDCButton
    },
    data() {
        return {
            filtered: 'all',
            experiences: null,
            next: null,
            municipio_list: MUNICIPIO_CODES_ALL
        }
    },
    async mounted() {
        this.getAllExperiences()
    },
    methods: {
        getAllExperiences() {
            Spinner.show()
            ExperiencesAPI.allExperiences().then(data => {
                this.experiences = data.results;
                this.next = data.next;
                Spinner.hide()
            })
        },
        getFilteredExperiences(municipio_name) {
            ExperiencesAPI.experiencesFilteredByMunicipio(municipio_name).then(data => {
                this.experiences = data.results;
                this.next = data.next;
            })
        },
        onFilterMunicipio(municipio_code) {
            const selector = document.querySelector("#municipio_selector")
            
            if(!municipio_code) {
                this.filtered = selector.value;
            } else {
                this.filtered = municipio_code;
                selector.value = this.filtered;
            }

            if (this.filtered === "all") {
                this.getAllExperiences()
            } else {
                this.getFilteredExperiences(this.filtered)
            } 
        },
        onNextButton() {
            fetch(this.next)
            .then(res => res.json())
            .then(data => {
                const results = data.data.results;

                if (results) {
                    this.next = data.data.next;

                    results.forEach(exp => {
                        this.experiences.push(exp)
                    })
                }
            })
        }
    }
}
</script>

<style>
/* HERO */
.exps-hero {
    width: 100%;
    height: 320px;
    overflow: hidden;
    position: relative;
}

.exps-hero__bk {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.exps-hero__bk::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .65);
}

.exps-hero__bk img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.exps-hero__content {
    max-width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    padding: 0px 40px;
    margin: 0 auto;
}

.exps-hero__title,
.exps-hero__description {
    text-align: center;
    color: white;
}

.exps-hero__title {
    font-size: 2.25rem;
}

.exps-hero__description {
    font-size: 1.2rem;
}

/* BODY */
.exps-body {
    width: 100%;
    max-width: 1000px;
    padding: 24px;
    margin: 0px auto;
    margin-bottom: 40px;
    display: flex;
    gap: 24px;
}

.exps-body__left {
    min-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    /* Hide on mobile */
    display: none;
}

.exps-body__left-title {
    font-size: 1.5rem;
}

.exps-municipio__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.exps-municipio {
    text-decoration: none;
    color: var(--color-dark);
    cursor: pointer;
}

.exps-municipio.active {
    color: var(--color-primary);
    font-weight: bold;
}

.exps-body__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.exps-body button {
    max-width: 250px;
    margin: 0 auto;
}

.exps-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
    gap: 16px;
}

@media (min-width: 800px) {
    .exps-body__left { display: flex }
    .exps-body__content .edc-selector { display: none }
}
</style>