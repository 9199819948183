const subscribers = []

class Alert {
    static open(type, message) {
        this.#send({ type, message })
    }

    static #send(data) {
        subscribers.forEach(subscriber => {
            subscriber(data)
        })
    }
    
    static subscribe(fn) {
        subscribers.push(fn)
    }
    
    static unsubscribe(fn) {
        const idx = subscribers.findIndex(item => item === fn)
        subscribers.splice(idx, 1)
    }
}

export {
    Alert
}