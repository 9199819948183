import { API } from './_API';
import { Auth } from './auth';

class RatingAPI {
    /**
     *  @param {rating} integer rating or stars  
     *  @param {business} integer business ID 
     *  @param {comment} string comment 
    */
    static async sendRating(rating, business, comment) {
        if (!rating) return;
        if (!business) return;
        if (!comment) return;
        return await fetch(`${API}/rating_comment/`, {
            method: "POST",
            body: JSON.stringify({ rating, business, comment }),
            headers: { 
                "Content-Type": "application/json",
                "Authorization": `Token ${Auth.getToken()}`, 
            }
        })
        .then(res => res.json())
        .then(data => {
            if (data.status !== 200) return new Error('Something went wrong')

            return data.data
        })
    }
}

export {
    RatingAPI
}