<template>
    <EDCHeader />
    <EDCHead title="Acerca de" />
    <div class="about__content">
        <img src="https://picsum.photos/1920/1080" class="about__image">
        <div class="about__body">
            <h1 class="about__title">Acerca de</h1>
            <div class="about__text">
                Enamórate del Cesar es una apuesta por la cultura y el patrimonio del departamento del Cesar, por recuperar nuestras tradiciones y raíces que persisten hoy en día, pero también rescatar las que hemos ido olvidando. Queremos generar la apropiación de las manifestaciones que nos son propias y reconocer la riqueza paisajística de nuestra tierra.
                <br>
                <br>
                Aquí nos apoyamos de la tecnología para acercar a las personas a pesar de las distancias, pero también llevar a otro nivel las experiencias en diferentes sitios de interés de cada municipio. Ponemos al alcance de cualquier persona experiencias interactivas a través de nuestra página web y aplicación móvil, que van desde videos en Full HD y en 360° hasta experiencias en realidad aumentada.
                <br>
                <br>
                <strong>¿Quienes somos?</strong>
                <br>
                <br>
                Para desarrollar este proyecto, la Universidad Popular del Cesar hizo una alianza con las empresas de tecnología Blazing Soft y Certika.
                <br>
                <br>
                <strong>Nuestro alcance</strong>
                <br>
                <br>
                Con este bello proyecto hemos llegado a cada municipio del departamento realizando investigación de los paisajes y cultura local. También hemos podido llevarlo a diferentes partes del mundo para dar a conocer sobre la riqueza del Cesar y cómo ha sido la experiencia de llevarlo a los usuarios a través de estas tecnologías.

            </div>
        </div>
    </div>
    <EDCFooter />
    <EDCMobileNav />
</template>

<script>
import EDCHeader from '../components/Header.vue';
import EDCHead from '../components/Head.vue';
import EDCFooter from '../components/Footer.vue';
import EDCMobileNav from '../components/MobileNav.vue';

export default {
    name: "edc-about-view",
    components: {
        EDCHeader,
        EDCHead,
        EDCFooter,
        EDCMobileNav
    }
}
</script>

<style>
.about__content {
    width: 100%;
    max-width: 1000px;
    padding: 0px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.about__body {
    padding: 0px 24px
}

.about__image {
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin-bottom: 24px;
}

.about__title {
    width: 100%;
    line-height: 110%;
    margin-bottom: 16px;
}

@media (min-width: 920px) {
    .about__content {
        padding: 0px 24px
    }

    .about__body {
        padding: 0px
    }
}</style>