<!-- This component must not be accessed directly from the app. It must be used doing click on a link with a token in it -->
<template>
    <div class="page-recover">
        <img class="logo" src="../assets/logo.png" />
        <h1 class="title">Recupera tu contraseña</h1>
        <div class="input-group">
            <p class="input-group__label">Nueva contraseña</p>
            <input type="password" />
        </div>
        <div class="input-group">
            <p class="input-group__label">Repetir contraseña</p>
            <input type="password" />
        </div>
        <div class="options">
            <EDCButton class="primary" label="Actualizar contraseña" />
        </div>
    </div>
</template>

<script>
import EDCButton from '../components/Button.vue';

export default {
    name: 'edc-recover-pass',
    components: {
        EDCButton
    }
}
</script>

<style>
.page-recover {
    width: 100%;
    height: 100vh;
    max-width: 340px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page-recover .logo {
    width: 160px;
    height: 160px;
    object-fit: contain;
    margin-bottom: 24px;
}

.page-recover .title {
    font-size: 1.5rem;
    margin-bottom: 24px;
    text-align: center;
}

.page-recover .input-group {
    width: 100%;
    margin-bottom: 16px;
}

.page-recover .input-group input {
    width: 100%;
}

.page-recover .options {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
</style>