import { API } from '../services/_API';
import { Alert } from '@/scripts/open-alert.js'

const subscribers = [];
let logged_in = false;

class Auth {
    static getToken() {
        const LS_TOKEN = localStorage.getItem('auth_token');
        if(LS_TOKEN) {
            logged_in = true;
            return LS_TOKEN
        } else {
            logged_in = false;
        }
    }

    static getUserDetails() {
        return {
            firstname: localStorage.getItem('auth_firstname'),
            lastname: localStorage.getItem('auth_lastname'),
            email: localStorage.getItem('auth_email')
        }
    }

    static saveTokenToLS(token) {
        localStorage.setItem('auth_token', token)
    }

    static async login(email, password) {
        if (!email) return;
        if (!password) return;

        return await fetch(`${API}/login/`, {
            method: "POST",
            body: JSON.stringify({ email, password }),
            headers: { "Content-Type": "application/json" }
        })
        .then(res => res.json())
        .then(data => {
            if (data.status === 200) {
                this.saveTokenToLS(data.token)
                
                logged_in = true;
                localStorage.setItem('auth_firstname', data.data.first_name)
                localStorage.setItem('auth_lastname', data.data.last_name)
                localStorage.setItem('auth_email', data.data.email)
                this.#sendLoginStatus(true);

                return data;
            }
            
            return data;
        })
    }

    static async loginProvider(fire_token) {
        if (!fire_token) return;

        return await fetch(`${API}/login_provider/`, {
            method: "POST",
            body: JSON.stringify({ fire_token }),
            headers: { "Content-Type": "application/json" }
        })
        .then(res => res.json())
        .then(data => {
            this.saveTokenToLS(data.token)
            
            logged_in = true;
            localStorage.setItem('auth_firstname', data.data.first_name)
            localStorage.setItem('auth_lastname', data.data.last_name)
            localStorage.setItem('auth_email', data.data.email)
            this.#sendLoginStatus(true);
            
            return data;
        })
    }

    static async remember(email) {
        if (!email) return;

        return await fetch(`${API}/recovery_password/`, {
            method: "POST",
            body: JSON.stringify({ email }),
            headers: { "Content-Type": "application/json" }
        })
        .then(res => res.json())
        .then(data => {
            return data;
        })
    }

    static async register(email, first_name, last_name, password) {
        if (!email || !first_name || !last_name || !password) return;

        return await fetch(`${API}/register_tourist/`, {
            method: "POST",
            body: JSON.stringify({ email, first_name, last_name, password }),
            headers: { "Content-Type": "application/json" }
        })
        .then(res => res.json())
        .then(data => {
            return data
        })
    }

    static async update(first_name, last_name) {
        if (!first_name || !last_name) return;

        return await fetch(`${API}/change_user_profile/`, {
            method: "POST",
            body: JSON.stringify({ first_name, last_name }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${Auth.getToken()}` 
            }
        })
        .then(res => res.json())
        .then(data => {
            if (data.status === 200) {
                localStorage.setItem('auth_firstname', first_name)
                localStorage.setItem('auth_lastname', last_name)

                return data;
            }
            
            return data;
        })
    }

    static async changePassword(password, new_password) {
        if (!password || !new_password) return;

        return await fetch(`${API}/change_password/`, {
            method: "POST",
            body: JSON.stringify({ password, new_password }),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${Auth.getToken()}` 
            }
        })
        .then(res => res.json())
        .then(data => {
            return data;
        })
    }

    static logOut() {
        logged_in = false;
        localStorage.removeItem('auth_token');
        localStorage.removeItem('auth_firstname');
        localStorage.removeItem('auth_lastname');
        localStorage.removeItem('auth_email');
        Alert.open("success", "Sesión cerrada correctamente")
        this.#sendLoginStatus(false);
    }

    static isAuthenticated() {
        return logged_in;
    }
    
    static #sendLoginStatus(isLoggedIn) {
        this.#send({ logged_in: isLoggedIn })
    }

    static #send(data) {
        subscribers.forEach(subscriber => {
            subscriber(data)
        })
    }
    
    static subscribe(fn) {
        subscribers.push(fn)
    }
    
    static unsubscribe(fn) {
        const idx = subscribers.findIndex(item => item === fn)
        subscribers.splice(idx, 1)
    }
}

Auth.getToken()

export {
    Auth
}