<template>
    <div class="page-change-pass">
        <img class="logo" src="../assets/logo.png" />
        <h1 class="title">Cambiar contraseña</h1>
        <div class="input-group">
            <p class="input-group__label">Contraseña actual</p>
            <input type="password" />
        </div>
        <div class="input-group">
            <p class="input-group__label">Nueva contraseña</p>
            <input type="password" />
        </div>
        <div class="input-group">
            <p class="input-group__label">Repetir contraseña</p>
            <input type="password" />
        </div>
        <div class="options">
            <EDCButton class="primary" label="Actualizar contraseña" />
            <EDCButton class="secondary" label="Atrás" type="secondary" @click="goBack()" />
        </div>
    </div>
</template>

<script>
import EDCButton from '../components/Button.vue';

export default {
    name: 'edc-change-pass-pass',
    components: {
        EDCButton
    },
    methods: {
        goBack() {
            this.$router.back()
        }
    }
}
</script>

<style>
.page-change-pass {
    width: 100%;
    height: 100vh;
    max-width: 340px;
    margin: 0 auto;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.page-change-pass .logo {
    width: 160px;
    height: 160px;
    object-fit: contain;
    margin-bottom: 24px;
}

.page-change-pass .title {
    font-size: 1.5rem;
    margin-bottom: 24px;
    text-align: center;
}

.page-change-pass .input-group {
    width: 100%;
    margin-bottom: 16px;
}

.page-change-pass .input-group input {
    width: 100%;
}

.page-change-pass .options {
    width: 100%;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
</style>