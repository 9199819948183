<template>
    <div class="edc-empty">
        <p>{{ message }}</p>
    </div>
</template>

<script>
export default {
    name: "edc-empty",
    props: {
        message: {
            type: String,
            required: true
        }
    }
}
</script>

<style>
.edc-empty {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eeeeee;
    color: rgba(0, 0, 0, 0.35);
    border: 1px solid #0000001a;
    border-radius: 8px;
}
</style>