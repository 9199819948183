<template>
    <EDCHeader />
    <div class="news-body">
        <div class="news-body__left">
            <h3 class="news-body__left-title">Municipio:</h3>
            <div class="news-municipio__list">
                <p
                    @click="onFilterMunicipio(municipio.code)"
                    class="news-municipio" 
                    :class="[filtered == municipio.code ? 'active' : '']" 
                    v-for="municipio in municipio_list"
                    :key="municipio.code">
                    {{ municipio.name }}
                </p>
            </div>
        </div>
        <div class="news-body__content">
            <div class="edc-section-head">
                <h1 class="edc-section-head__title">Noticias</h1>
                <p class="edc-section-head__description">
                    En los diferentes municipios del Cesar puedes encontrar noticias relevantes.
                </p>
            </div>
            <div class="edc-selector">
                <select name="municipio_selector" id="municipio_selector" @change="onFilterMunicipio()">
                    <option :value="municipio.code" :key="municipio.code" v-for="municipio in municipio_list">{{ municipio.name }}</option>
                </select>
            </div>
            <div class="news-list">
                <EDCNew 
                v-for="single in news"
                :key="single.id"
                :id="single.id" 
                date="31 de Octubre, 2023" 
                :name="single.title" 
                :image="single.image" />
            </div>
            <EDCButton label="Cargar más" v-show="next" @click="onNextButton()" />
        </div>
    </div>
    <EDCFooter />
    <EDCMobileNav />
</template>

<script>
import EDCHeader from '../components/Header.vue';
import EDCFooter from '../components/Footer.vue';
import EDCMobileNav from '../components/MobileNav.vue';
import EDCNew from '../components/New.vue';
import EDCButton from '../components/Button.vue';
import { Spinner } from '@/scripts/open-spinner';

import { MUNICIPIO_CODES_ALL } from '../scripts/municipio-codes';
import { NewsAPI } from '@/services/news';

export default {
    name: "edc-businesses-view",
    components: {
        EDCHeader,
        EDCFooter,
        EDCMobileNav,
        EDCNew,
        EDCButton
    },
    data() {
        return {
            filtered: 'all',
            news: [],
            next: null,
            municipio_list: MUNICIPIO_CODES_ALL
        }
    },
    mounted() {
        this.getAllNews()
    },
    methods: {
        getAllNews() {
            Spinner.show()
            NewsAPI.allNews().then(data => {
                this.news = data.results;
                this.next = data.next;
                Spinner.hide()
            })
        },
        getFilteredNews(municipio_name) {
            NewsAPI.newsFilteredByMunicipio(municipio_name).then(data => {
                this.news = data.results;
                this.next = data.next;
            })
        },
        onFilterMunicipio(municipio_name) {
            const selector = document.querySelector("#municipio_selector")
            
            if (!municipio_name) {
                this.filtered = selector.value;
            } else {
                this.filtered = municipio_name;
                selector.value = this.filtered;
            }

            if (this.filtered === "all") {
                this.getAllNews()
            } else {
                this.getFilteredNews(this.filtered)
            } 
        },
        onNextButton() {
            fetch(this.next)
            .then(res => res.json())
            .then(data => {
                const results = data.data.results;

                if (results) {
                    this.next = data.data.next;

                    results.forEach(exp => {
                        this.experiences.push(exp)
                    })
                }
            })
        }
    }
}
</script>

<style>
/* BODY */
.news-body {
    width: 100%;
    max-width: 1000px;
    padding: 24px;
    margin: 0px auto;
    margin-bottom: 40px;
    display: flex;
    gap: 24px;
}

.news-body__left {
    min-width: 180px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    /* Hide on mobile */
    display: none;
}

.news-body__left-title {
    font-size: 1.5rem;
}

.news-municipio__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.news-municipio {
    text-decoration: none;
    color: var(--color-dark);
    cursor: pointer;
}

.news-municipio.active {
    color: var(--color-primary);
    font-weight: bold;
}

.news-body__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.news-body button {
    max-width: 250px;
    margin: 0 auto;
}

.news-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
    gap: 16px;
}

@media (min-width: 800px) {
    .news-body__left { display: flex }
    .news-body__content .edc-selector { display: none }
}
</style>