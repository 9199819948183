<template>
    <EDCHeader />
    <EDCHead :title="municipio.name" />
    <!-- HERO -->
    <div class="municipio-hero">
        <div class="municipio-hero__bk"><img :src="municipio_hero.image" ></div>
        <h1 class="municipio-hero__name">{{ municipio.name }}</h1>
        <p class="municipio-hero__phrase">{{ municipio.phrase }}</p>
        <section class="splide municipio-hero__splide" id="hero-splide">
            <div class="splide__track">
                <ul class="splide__list">
                    <EDCExperience 
                    v-for="exp in municipio_hero.experiences"
                    :key="exp.id"
                    :id="exp.id"
                    :name="exp.name" 
                    :municipio="exp.municipality"
                    :image="exp.thumbnail" 
                    :type="exp.type" 
                    class="splide__slide" />
                </ul>
            </div>
        </section>
    </div>
    <!-- INFO -->
    <div class="municipio-info">
        <div class="municipio-info__content">
            <img class="municipio-info__illustration" :src="municipio.illustration" />
            <div class="municipio-info__inner">
                <p class="municipio-info__phrase">{{ municipio.phrase }}</p>
                <div class="municipio-info__head">
                    <h1 class="municipio-info__title">{{ municipio.name }}</h1>
                    <img class="municipio-info__flag" :src="municipio.flag" />
                </div>
                <p class="municipio-info__description">{{ municipio.description }}</p>
            </div>
        </div>
    </div>
    <!-- EXPERIENCES -->
    <div class="municipio-experiences">
        <div class="municipio-experiences__tabs">
            <button 
                v-for="(site, idx) in municipio.touristic_sites"
                :key="idx"
                :class="[selected_tab === idx ? 'active' : '']"
                @click="setTab(idx)"
                class="municipio-experiences__tab-item">
                Sitio #{{idx + 1}}
            </button>
        </div>
        <!-- TAB 1 -->
        <div class="municipio-experiences__tab-content" 
        v-for="(site, idx) in municipio.touristic_sites" 
        v-show="selected_tab === idx"
        :key="idx">
            <h2 class="municipio-experiences__title">{{ site.name }}</h2>
            <p class="municipio-experiences__description">{{ site.description }}</p>
            <section class="splide municipio-experiences__splide" :id="`experiences-splide-${idx}`">
                <div class="splide__track">
                    <ul class="splide__list">
                        <EDCExperience 
                        v-for="exp in site.experiences"
                        :key="exp.id"
                        :id="exp.id"
                        :name="exp.name"
                        :municipio="exp.municipality" 
                        :image="exp.thumbnail" 
                        :type="exp.type" 
                        class="splide__slide" />
                    </ul>
                </div>
            </section>
        </div>
    </div>
    <!-- BUSINESSES -->
    <div class="municipio-businesses">
        <div class="municipio-businesses__content">
            <img class="municipio-businesses__image" src="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="">
            <div class="municipio-businesses__info">
                <h3 class="municipio-businesses__title">¿Buscas algo qué hacer en {{ municipio.name }}?</h3>
                <p class="municipio-businesses__description">Conocer los servicios disponibles en el municipio.</p>
                <EDCButton label="Explorar" @click="goToMunicipioBusinesses(municipio.id)" />
            </div>
        </div>
    </div>
    <!-- QUOTE -->
    <div class="municipio-quote">
        <h2 class="municipio-quote-title">Frase típica:</h2>
        <div class="municipio-quote__content">
            <p class="municipio-quote__text" v-html="municipio.quote_text"></p>
            <h3 class="municipio-quote__title">{{ municipio.quote_name }}</h3>
            <p class="municipio-quote__subtitle">{{ municipio.quote_title }}</p>
        </div>
    </div>
    <!-- RECOMMENDATIONS -->
    <div class="municipio-more">
        <div class="municipio-more__content">
            <div class="municipio-more__info">
                <h2 class="municipio-more__title">Explora otros municipios</h2>
                <p class="municipio-more__subtitle">Hay 24 municipios mas que puedes descubrir, te recomendamos algunos:</p>
            </div>
            <section class="splide municipio-more__splide" id="more-splide">
                <div class="splide__track">
                    <ul class="splide__list">
                        <EDCMunicipio 
                        v-for="municipio in recommendations"
                        :key="municipio.id"
                        :id="municipio.id"
                        :name="municipio.name" 
                        :image="municipio.images[Math.floor(Math.random()*municipio.images.length)].file"
                        class="splide__slide" />
                    </ul>
                </div>
            </section>
        </div>
    </div>
    <EDCFooter />
    <EDCMobileNav />
</template>

<script>
import Splide from '@splidejs/splide';
import { MunicipioAPI } from '@/services/municipios';
import { Spinner } from '@/scripts/open-spinner';

import EDCHeader from '../components/Header.vue';
import EDCHead from '../components/Head.vue';
import EDCFooter from '../components/Footer.vue';
import EDCMobileNav from '../components/MobileNav.vue';
import EDCExperience from '../components/Experience.vue';
import EDCButton from '../components/Button.vue';
import EDCMunicipio from '../components/Municipio.vue';

export default {
    name: 'edc-municipio-view',
    components: {
        EDCHeader,
        EDCHead,
        EDCFooter,
        EDCMobileNav,
        EDCExperience,
        EDCButton,
        EDCMunicipio
    },
    data() {
        return {
            hero_splide: null,
            experiences_splide_1: null,
            experiences_splide_2: null,
            selected_tab: 0,
            more_splide: null,
            municipio: {},
            municipio_hero: { experiences: null, image: null },
            recommendations: []
        }
    },
    methods: {
        setTab(number) {
            this.selected_tab = number
        },
        mountSplides() {
            this.hero_splide = new Splide('#hero-splide', {
                type: "slide",
                perPage: 1,
                speed: 750,
                gap: '12px',
                padding: '20px',
                pauseOnHover: false,
                pagination: false,
                mediaQuery: 'min',
                breakpoints: {
                    450: {
                        perPage: 2
                    },
                    740: {
                        perPage: 3
                    }
                }
            }).mount()

            this.experiences_splide_1 = new Splide('#experiences-splide-0', {
                type: "slide",
                perPage: 1,
                speed: 750,
                gap: '12px',
                padding: '20px',
                pauseOnHover: false,
                pagination: false,
                mediaQuery: 'min',
                breakpoints: {
                    450: {
                        perPage: 2
                    },
                    740: {
                        perPage: 3
                    }
                }
            }).mount()

            this.experiences_splide_2 = new Splide('#experiences-splide-1', {
                type: "slide",
                perPage: 1,
                speed: 750,
                gap: '12px',
                padding: '20px',
                pauseOnHover: false,
                pagination: false,
                mediaQuery: 'min',
                breakpoints: {
                    450: {
                        perPage: 2
                    },
                    740: {
                        perPage: 3
                    }
                }
            }).mount()

            this.more_splide = new Splide('#more-splide', {
                type: "slide",
                perPage: 1,
                speed: 750,
                gap: '12px',
                padding: '20px',
                pauseOnHover: false,
                pagination: false,
                mediaQuery: 'min',
                breakpoints: {
                    450: {
                        perPage: 2
                    },
                    740: {
                        perPage: 3,
                        arrows: false
                    }
                }
            }).mount()
        },
        getRandomHero(touristic_sites, images) {
            const experiences = []

            touristic_sites.forEach(site => {
                site.experiences.forEach(exp => {
                    experiences.push(exp)
                })
            })

            this.municipio_hero.experiences = this.shuffleArray(experiences).splice(3, 999)
            this.municipio_hero.image = images[Math.floor(Math.random()*images.length)].file
        },
        shuffleArray(array) {
            const baseArray = array;

            for (let i = baseArray.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                [baseArray[i], baseArray[j]] = [baseArray[j], baseArray[i]];
            }

            return baseArray;
        },
        goToMunicipioBusinesses(id) {
            this.$router.push({ name: "municipio-businesses", params: { id: id } })
        }
    },
    async mounted() {
        const self = this;
        const municipioID = parseInt(this.$route.params.id);

        Spinner.show()
        MunicipioAPI.getMunicipio(municipioID).then((data) => {
            Spinner.hide()
            self.municipio = data;
            self.getRandomHero(data.touristic_sites, data.images)
        })

        this.recommendations = await MunicipioAPI.getMunicipioRecommendations(municipioID)
    },
    async beforeRouteUpdate(to) {
        const self = this;
        const municipioID = parseInt(to.params.id);

        Spinner.show()
        MunicipioAPI.getMunicipio(municipioID).then((data) => {
            Spinner.hide()
            self.municipio = data;
            self.getRandomHero(data.touristic_sites, data.images)
        })

        this.recommendations = await MunicipioAPI.getMunicipioRecommendations(municipioID)
    },
    updated() {
        this.mountSplides()
    }
}
</script>

<style>
/* HERO */
.municipio-hero {
    width: 100%;
    height: 75vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-bottom: 20px;
}

.municipio-hero__name {
    width: 100%;
    max-width: 1000px;
    padding: 0px 24px;
    margin: 0 auto;
    color: white;
    z-index: 9;
    font-size: 2.5rem;
    text-shadow: 0px 2px 16px rgba(0, 0, 0, 0.5);
}

.municipio-hero__phrase {
    width: 100%;
    max-width: 1000px;
    padding: 0px 24px;
    margin: 0 auto;
    margin-bottom: 16px;
    color: white;
    z-index: 9;
    margin-bottom: 24px;
}

.municipio-hero__bk {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.municipio-hero__bk::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
}

.municipio-hero__bk img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.municipio-hero__splide {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 1000px;
}

.municipio-hero__splide,
.municipio-hero__splide .splide__track {
    width: 100%;
    margin: 0 auto;
}

/* INFO */
.municipio-info {
    width: 100%;
    max-width: 1000px;
    padding: 0px 24px;
    margin: 32px auto;
}

.municipio-info__illustration {
    width: 100%;
    height: 250px;
    object-fit: contain;
    margin-bottom: 16px;
}

.municipio-info__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 24px;
}

.municipio-info__title {
    font-size: 2.2rem;
    line-height: 115%;
}

.municipio-info__description {
    font-size: 1.1rem;
    line-height: 150%;
}

.municipio-info__flag {
    height: 32px;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.35);
    border-radius: 2px;
    overflow: hidden;
}

/* EXPERIENCES */
.municipio-experiences {
    width: 100%;
    max-width: 1000px;
    padding: 0px 24px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.municipio-experiences__title {
    padding: 0px 24px;
    margin-bottom: 16px;
    color: white;
}

.municipio-experiences__description {
    font-size: 1.1rem;
    line-height: 150%;
    padding: 0px 24px;
    margin-bottom: 24px;
    color: white;
}

.municipio-experiences__tab-content {
    border-radius: 0px 0px 16px 16px;
    background-color: var(--color-primary);
    overflow: hidden;
    padding: 24px 0px;
}

.municipio-experiences__tabs {
    width: 100%;
    max-width: 400px;
    border-radius: 16px 16px 0px 0px;
    overflow: hidden;
    display: flex;
    border: 3px solid #a153aa;
    position: relative;
    top: 2px;
}

.municipio-experiences__tab-item {
    width: 100%;
    height: 50px;
    background: none;
    border: none;
    outline: none;
    background-color: #fef3ff;
    font-size: 1.1rem;
    color: #a153aa;
    font-weight: bold;
    cursor: pointer;
}

.municipio-experiences__tab-item.active {
    background-color: var(--color-primary);
    color: white;
}

.municipio-experiences__splide {
    display: flex;
    align-items: center;
    justify-content: center;
}

.municipio-experiences__splide,
.municipio-experiences__splide .splide__track {
    width: 100%;
}

/* BUSINESSES */
.municipio-businesses {
    width: 100%;
    max-width: 1000px;
    padding: 0px 24px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.municipio-businesses__content {
    width: 100%;
    background-color: white;
    border: 1px solid rgba(80, 59, 47, 0.35);
    border-radius: 16px;
    overflow: hidden;
}

.municipio-businesses__image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.municipio-businesses__info {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.municipio-businesses__title {
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 6px;
}

.municipio-businesses__description {
    font-size: 1.1rem;
    text-align: center;
}

.municipio-businesses__info button {
    max-width: 200px;
    margin-top: 24px;
}

/* QUOTE */
.municipio-quote {
    width: 100%;
    max-width: 1000px;
    padding: 0px 24px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.municipio-quote-title {
    font-size: 1.8rem;
    margin-bottom: 8px;
}

.municipio-quote__content {
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    background-color: rgba(82, 156, 213, 0.25);
    padding: 32px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.municipio-quote__text,
.municipio-quote__title,
.municipio-quote__subtitle {
    text-align: center;
    max-width: 600px;
}

.municipio-quote__text {
    font-size: 1.1rem;
    margin-bottom: 16px;
}

.municipio-quote__title {
    font-size: 1.35rem;
}

/* RECOMMENDATIONS */
.municipio-more {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.municipio-more__info {
    padding: 0px 24px;
    margin-bottom: 16px;
}

.municipio-more__title {
    font-size: 1.8rem;
}

.municipio-more__subtitle {
    font-size: 1.1rem;
}

.municipio-more__splide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.municipio-more__splide,
.municipio-more__splide .splide__track,
.municipio-more__splide .splide__list {
    width: 100%;
}

@media (min-width: 920px) {
    .municipio-info__content { 
        display: flex; 
        align-items: center; 
        gap: 40px;
        background-color: white;
        padding: 24px 32px;
        border-radius: 16px;
        overflow: hidden;
        border: 1px solid rgba(80, 59, 47, 0.25);
    }

    .municipio-info__illustration { margin: 0 }

    .municipio-experiences__tab-content {
        border-radius: 0px 16px 16px 16px;
    }

    .municipio-businesses__content {
        display: flex;
    }

    .municipio-businesses__image {
        width: 340px;
        height: 100%;
    }

    .municipio-businesses__info {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 24px 40px;
    }

    .municipio-businesses__title,
    .municipio-businesses__description {
        text-align: left;
    }
}
</style>