<template>
    <button :class="`edc-button ${type}`">{{ label }}</button>
</template>

<script>
export default {
    name: "edc-button",
    props: ["label", "type"],
}
</script>

<style>
.edc-button {
    width: 100%;
    height: 48px;
    background-color: var(--color-primary);
    border-radius: 200px;
    box-shadow: 0px 2px 8px 0px rgba(161, 83, 170, 0.50);
    outline: none;
    border: none;
    color: #FFFEEC;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-decoration: none;
    padding-top: 2px;
    cursor: pointer;
    transition: all .15s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.edc-button:hover {
    filter: brightness(1.1);
}

.edc-button.secondary {
    background-color: white;
    border: 1px solid var(--color-primary);
    color: var(--color-primary);
    box-shadow: none;
}

.edc-button.secondary:hover {
    filter: brightness(0.95);
}
</style>