<template>
    <EDCHeader />
    <EDCHead title="Detalles de negocio" />
    <!-- HERO -->
    <div class="b-details__hero">
        <div class="b-details__hero-bk"><img :src="info.image" /></div>
    </div>
    <!-- TABS -->
    <div class="b-details__tabs">
        <div class="b-details__tabs-content">
            <button 
                class="b-details__tab-item" 
                :class="[activeTab == 'info' ? 'active' : '']"
                @click="setTab('info')">
                Info
            </button>
            <button 
                class="b-details__tab-item" 
                :class="[activeTab == 'services' ? 'active' : '']"
                @click="setTab('services')">
                Servicios
            </button>
            <button 
                class="b-details__tab-item" 
                :class="[activeTab == 'ratings' ? 'active' : '']"
                @click="setTab('ratings')">
                Calificaciones
            </button>
        </div>
    </div>
    <!-- TAB CONTENT -->
    <div class="b-details__info" v-show="activeTab == 'info'">
        <!-- SLIDER -->
        <div class="b-images-splide__container" v-show="info.images?.length">
            <section class="splide" id="images-splide">
                <div class="splide__track">
                    <ul class="splide__list" id="gallery">
                        <a :href="slide.file"
                        class="splide__slide b-image-slide" 
                        v-for="slide in info.images" 
                        :key="slide.id"
                        :data-pswp-width="slide.width" 
                        :data-pswp-height="slide.height" 
                        target="_blank">
                            <img :src="slide.file" />
                        </a>
                    </ul>
                </div>
            </section>
        </div>
        <div class="b-details__all-info">
            <!-- GENERAL -->
            <div class="b-details__general-info">
                <p class="b-details__type">{{ getBusinessType(info.type) }}</p>
                <h1 class="b-details__name">{{ info.name }}</h1>
                <div class="b-details__stars">
                    <div class="b-details__stars-content" v-show="info.rating > 0">
                        <EDCStars :quantity="info.rating" :star_size="18" />
                        <p>{{ info.rating }}</p>
                    </div>
                    <p class="no-stars" v-show="info.rating <= 0">Sin calificaciones</p>
                </div>
                <p class="b-details__address"><span>Dirección:</span> {{ info.address }}</p>
                <p class="b-details__city">{{ info.municipio }}</p>
                <p class="b-details__description">{{ info.description }}</p>
                <div class="b-details__services-list">
                    <div class="b-details__service-item" :class="[info.wifi ? 'active' : '']">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10.6 13.8L8.45 11.65C8.26667 11.4667 8.03333 11.375 7.75 11.375C7.46667 11.375 7.23333 11.4667 7.05 11.65C6.86667 11.8333 6.775 12.0667 6.775 12.35C6.775 12.6333 6.86667 12.8667 7.05 13.05L9.9 15.9C10.1 16.1 10.3333 16.2 10.6 16.2C10.8667 16.2 11.1 16.1 11.3 15.9L16.95 10.25C17.1333 10.0667 17.225 9.83333 17.225 9.55C17.225 9.26667 17.1333 9.03333 16.95 8.85C16.7667 8.66667 16.5333 8.575 16.25 8.575C15.9667 8.575 15.7333 8.66667 15.55 8.85L10.6 13.8ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z" />
                        </svg>
                        WIFI
                    </div>
                    <div class="b-details__service-item" :class="[info.ac ? 'active' : '']">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10.6 13.8L8.45 11.65C8.26667 11.4667 8.03333 11.375 7.75 11.375C7.46667 11.375 7.23333 11.4667 7.05 11.65C6.86667 11.8333 6.775 12.0667 6.775 12.35C6.775 12.6333 6.86667 12.8667 7.05 13.05L9.9 15.9C10.1 16.1 10.3333 16.2 10.6 16.2C10.8667 16.2 11.1 16.1 11.3 15.9L16.95 10.25C17.1333 10.0667 17.225 9.83333 17.225 9.55C17.225 9.26667 17.1333 9.03333 16.95 8.85C16.7667 8.66667 16.5333 8.575 16.25 8.575C15.9667 8.575 15.7333 8.66667 15.55 8.85L10.6 13.8ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z" />
                        </svg>
                        Aire acondicionado
                    </div>
                    <div class="b-details__service-item" :class="[info.mobile_network ? 'active' : '']">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10.6 13.8L8.45 11.65C8.26667 11.4667 8.03333 11.375 7.75 11.375C7.46667 11.375 7.23333 11.4667 7.05 11.65C6.86667 11.8333 6.775 12.0667 6.775 12.35C6.775 12.6333 6.86667 12.8667 7.05 13.05L9.9 15.9C10.1 16.1 10.3333 16.2 10.6 16.2C10.8667 16.2 11.1 16.1 11.3 15.9L16.95 10.25C17.1333 10.0667 17.225 9.83333 17.225 9.55C17.225 9.26667 17.1333 9.03333 16.95 8.85C16.7667 8.66667 16.5333 8.575 16.25 8.575C15.9667 8.575 15.7333 8.66667 15.55 8.85L10.6 13.8ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z" />
                        </svg>
                        Redes moviles
                    </div>
                    <div class="b-details__service-item" :class="[info.parking_service ? 'active' : '']">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10.6 13.8L8.45 11.65C8.26667 11.4667 8.03333 11.375 7.75 11.375C7.46667 11.375 7.23333 11.4667 7.05 11.65C6.86667 11.8333 6.775 12.0667 6.775 12.35C6.775 12.6333 6.86667 12.8667 7.05 13.05L9.9 15.9C10.1 16.1 10.3333 16.2 10.6 16.2C10.8667 16.2 11.1 16.1 11.3 15.9L16.95 10.25C17.1333 10.0667 17.225 9.83333 17.225 9.55C17.225 9.26667 17.1333 9.03333 16.95 8.85C16.7667 8.66667 16.5333 8.575 16.25 8.575C15.9667 8.575 15.7333 8.66667 15.55 8.85L10.6 13.8ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z" />
                        </svg>
                        Parqueadero a clientes
                    </div>
                    <div class="b-details__service-item" :class="[info.food_service ? 'active' : '']">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M10.6 13.8L8.45 11.65C8.26667 11.4667 8.03333 11.375 7.75 11.375C7.46667 11.375 7.23333 11.4667 7.05 11.65C6.86667 11.8333 6.775 12.0667 6.775 12.35C6.775 12.6333 6.86667 12.8667 7.05 13.05L9.9 15.9C10.1 16.1 10.3333 16.2 10.6 16.2C10.8667 16.2 11.1 16.1 11.3 15.9L16.95 10.25C17.1333 10.0667 17.225 9.83333 17.225 9.55C17.225 9.26667 17.1333 9.03333 16.95 8.85C16.7667 8.66667 16.5333 8.575 16.25 8.575C15.9667 8.575 15.7333 8.66667 15.55 8.85L10.6 13.8ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6867 5.825 19.9743 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26333 14.6833 2.00067 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31333 6.88333 4.02567 5.825 4.925 4.925C5.825 4.025 6.88333 3.31267 8.1 2.788C9.31667 2.26333 10.6167 2.00067 12 2C13.3833 2 14.6833 2.26267 15.9 2.788C17.1167 3.31333 18.175 4.02567 19.075 4.925C19.975 5.825 20.6877 6.88333 21.213 8.1C21.7383 9.31667 22.0007 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6867 17.1167 19.9743 18.175 19.075 19.075C18.175 19.975 17.1167 20.6877 15.9 21.213C14.6833 21.7383 13.3833 22.0007 12 22Z" />
                        </svg>
                        Servicio de comidas
                    </div>
                </div>
                <div class="b-details__phones-emails">
                    <div class="b-details__phones" v-show="info.telephone_main || info.telephone_secondary">
                        <p class="b-details__phone-item" v-show="info.telephone_main">{{ info.telephone_main }}</p>
                        <p class="b-details__phone-item" v-show="info.telephone_secondary">{{ info.telephone_secondary }}</p>
                    </div>
                    <div class="b-details__emails" v-show="info.email_main || info.email_secondary">
                        <p class="b-details__email-item" v-show="info.email_main">{{ info.email_main }}</p>
                        <p class="b-details__email-item" v-show="info.email_secondary">{{ info.email_secondary }}</p>
                    </div>
                </div>
                <div class="b-details__social">
                    <a v-show="info.whatsapp" class="b=details__social-item" :href="'https://wa.me/57' + info.whatsapp" target="_blank">
                        <img src="../assets/social-media/phone.svg" alt="">
                    </a>
                    <a v-show="info.facebook" class="b=details__social-item" :href="info.facebook" target="_blank">
                        <img src="../assets/social-media/facebook.svg" alt="">
                    </a>
                    <a v-show="info.x_twitter" class="b=details__social-item" :href="info.x_twitter" target="_blank">
                        <img src="../assets/social-media/twitter.svg" alt="">
                    </a>
                    <a v-show="info.instagram" class="b=details__social-item" :href="info.instagram" target="_blank">
                        <img src="../assets/social-media/instagram.svg" alt="">
                    </a>
                    <a v-show="info.tiktok" class="b=details__social-item" :href="info.tiktok" target="_blank">
                        <img src="../assets/social-media/tiktok.svg" alt="">
                    </a>
                </div>
            </div>
            <!-- SCHEDULE -->
            <div class="b-details__schedule">
                <div class="b-details__schedule-list">
                    <EDCScheduleItem 
                        day="Lunes" 
                        :open_24="info.monday_open_24" 
                        :close_24="info.monday_closed24" 
                        :open_1="info.monday_open"
                        :close_1="info.monday_closed"
                        :open_2="info.monday_open_2"
                        :close_2="info.monday_closed_2" 
                    />
                    <EDCScheduleItem 
                        day="Martes" 
                        :open_24="info.tuesday_open_24" 
                        :close_24="info.tuesday_closed24" 
                        :open_1="info.tuesday_open"
                        :close_1="info.tuesday_closed"
                        :open_2="info.tuesday_open_2"
                        :close_2="info.tuesday_closed_2" 
                    />
                    <EDCScheduleItem 
                        day="Miércoles" 
                        :open_24="info.wednesday_open_24" 
                        :close_24="info.wednesday_closed24" 
                        :open_1="info.wednesday_open"
                        :close_1="info.wednesday_closed"
                        :open_2="info.wednesday_open_2"
                        :close_2="info.wednesday_closed_2" 
                    />
                    <EDCScheduleItem 
                        day="Jueves" 
                        :open_24="info.thursday_open_24" 
                        :close_24="info.thursday_closed24" 
                        :open_1="info.thursday_open"
                        :close_1="info.thursday_closed"
                        :open_2="info.thursday_open_2"
                        :close_2="info.thursday_closed_2" 
                    />
                    <EDCScheduleItem 
                        day="Viernes" 
                        :open_24="info.friday_open_24" 
                        :close_24="info.friday_closed24" 
                        :open_1="info.friday_open"
                        :close_1="info.friday_closed"
                        :open_2="info.friday_open_2"
                        :close_2="info.friday_closed_2" 
                    />
                    <EDCScheduleItem 
                        day="Sábado" 
                        :open_24="info.saturday_open_24" 
                        :close_24="info.saturday_closed24" 
                        :open_1="info.saturday_open"
                        :close_1="info.saturday_closed"
                        :open_2="info.saturday_open_2"
                        :close_2="info.saturday_closed_2" 
                    />
                    <EDCScheduleItem 
                        day="Domingo" 
                        :open_24="info.sunday_open_24" 
                        :close_24="info.sunday_closed24" 
                        :open_1="info.sunday_open"
                        :close_1="info.sunday_closed"
                        :open_2="info.sunday_open_2"
                        :close_2="info.sunday_closed_2" 
                    />
                </div>
            </div>
        </div>
        <div class="b-details__file-list" v-show="info.attachments?.length">
            <div class="b-details__file-item" v-for="item in info.attachments" :key="item.id">
                <div class="b-details__file-item-content">
                    <p class="b-details__file-name">{{ item.name }}</p>
                    <!-- <p class="b-details__file">carta_de_precios.pdf</p> -->
                </div>
                <a class="b-details__download-file" :href="item.file" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path d="M15 19.4688C14.8333 19.4688 14.6771 19.4429 14.5313 19.3913C14.3854 19.3396 14.25 19.2508 14.125 19.125L9.625 14.625C9.375 14.375 9.255 14.0833 9.265 13.75C9.275 13.4167 9.395 13.125 9.625 12.875C9.875 12.625 10.1721 12.495 10.5163 12.485C10.8604 12.475 11.1571 12.5946 11.4063 12.8438L13.75 15.1875V6.25C13.75 5.89584 13.87 5.59875 14.11 5.35875C14.35 5.11875 14.6467 4.99917 15 5C15.3542 5 15.6513 5.12 15.8913 5.36C16.1313 5.6 16.2508 5.89667 16.25 6.25V15.1875L18.5938 12.8438C18.8438 12.5938 19.1408 12.4738 19.485 12.4838C19.8292 12.4938 20.1258 12.6242 20.375 12.875C20.6042 13.125 20.7242 13.4167 20.735 13.75C20.7458 14.0833 20.6258 14.375 20.375 14.625L15.875 19.125C15.75 19.25 15.6146 19.3388 15.4688 19.3913C15.3229 19.4438 15.1667 19.4696 15 19.4688ZM7.5 25C6.8125 25 6.22375 24.755 5.73375 24.265C5.24375 23.775 4.99917 23.1867 5 22.5V20C5 19.6458 5.12 19.3488 5.36 19.1088C5.6 18.8688 5.89667 18.7492 6.25 18.75C6.60417 18.75 6.90125 18.87 7.14125 19.11C7.38125 19.35 7.50084 19.6467 7.5 20V22.5H22.5V20C22.5 19.6458 22.62 19.3488 22.86 19.1088C23.1 18.8688 23.3967 18.7492 23.75 18.75C24.1042 18.75 24.4013 18.87 24.6413 19.11C24.8813 19.35 25.0008 19.6467 25 20V22.5C25 23.1875 24.755 23.7763 24.265 24.2663C23.775 24.7563 23.1867 25.0008 22.5 25H7.5Z" />
                    </svg>
                </a>
            </div>
        </div>
    </div>
    <div class="b-details__info" v-show="activeTab == 'services'">
        <div class="b-details__services">
            <EDCServiceTypeFilter @filter_change="getBusinessServices" />
            <EDCEmpty message="No se encontraron servicios de este tipo" v-show="!services.length" />
            <div class="b-details__services-list" v-show="services.length">
                <div class="b-details__service" v-for="service in services" :key="service.id" @click="getServiceDetail(service.id)">
                    <div class="b-details__service-image">
                        <img :src="service.image" />
                    </div>
                    <div class="b-details__service-info">
                        <p class="b-details__service-title">{{ service.title }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="b-details__info" v-show="activeTab == 'ratings'">
        <div class="b-details__ratings">
            <div class="b-details__rating-head">
                <div class="rating-authenticated" v-show="logged_in && !rating.own">
                    <p class="rating-authenticated__description">¿Haz visitado este lugar? Deja una reseña y haznos saber que tal te ha parecido</p>
                    <EDCButton label="Reseñar" @click="showRateModal(true)" />
                </div>
                <div class="rating-authenticated" v-show="!logged_in">
                    <p class="rating-authenticated__description">Regístrate en la plataforma para poder reseñar este lugar</p>
                    <EDCButton label="Registrarme" @click="openRegisterModal()" />
                </div>
            </div>
            <div class="rating-avg" v-show="rating.list?.length">
                <h2 class="rating-avg__value">{{ rating.average }}</h2>
                <div class="rating-avg__details">
                    <EDCStars :quantity="rating.average" :star_size="18" />
                    <p class="rating-avg__total">{{ rating.count }} Calificaciones</p>
                </div>
            </div>
            <h2 class="b-details__rating-list-title" v-show="rating.list?.length">Todos los comentarios:</h2>
            <div class="b-details__rating-list" v-show="rating.list">
                <div class="rate-item" v-for="review in rating.list" :key="review.id">
                    <div class="rate-item__head">
                        <h3 class="rate-item__title">{{ review.user }}</h3>
                        <EDCStars :quantity="review.rating" :star_size="18" />
                    </div>
                    <p class="rate-item__description">{{ review.comment }}</p>
                </div>
            </div>
            <EDCEmpty message="No hay comentarios aún" v-show="!rating.list?.length" />
        </div>
    </div>
    <!-- SERVICE DETAILS -->
    <EDCServiceDetail 
    v-show="service_detail.modal" 
    :data="service_detail.data" 
    :type="service_detail.data?.service"
    @close="openServiceModal(false)" />
    <!-- RATE FORM -->
    <div class="rate-form" v-show="rate_form">
        <div class="rate-form__overlay" @click="showRateModal(false)"></div>
        <form class="rate-form__content" @submit="onRateModalSubmit">
            <div class="rate-form__ratings-stars">
                <EDCStars :editable="true" :quantity="rate.stars" :star_size="32" @star_clicked="onFormStarClicked" />
            </div>
            <!-- STARS NUMBER -->
            <input 
            class="rate-form__input-stars" 
            type="number" 
            min="1" 
            max="5" 
            v-model="rate.stars" 
            required>
            <!-- COMMENT -->
            <textarea 
            class="rate-form__input-comment" 
            v-model="rate.comment" 
            placeholder="Escribe aquí tu opinion sobre este sitio"
            required>
            </textarea>
            <!-- OPTIONS -->
            <div class="rate-form__options">
                <EDCButton label="Enviar" />
                <a class="rate-form__close" @click="showRateModal(false)">Cerrar</a>
            </div>
        </form>
    </div>
    <EDCSpinner type="fixed" :show="spinner" />
    <EDCFooter />
    <EDCMobileNav />
</template>

<script>
import Splide from '@splidejs/splide';
import PhotoSwipeLightbox from 'photoswipe/lightbox';

import EDCHeader from '../components/Header.vue';
import EDCHead from '../components/Head.vue';
import EDCMobileNav from '../components/MobileNav.vue';
import EDCFooter from '../components/Footer.vue';
import EDCButton from '../components/Button.vue'
import EDCStars from '../components/Stars.vue';
import EDCScheduleItem from '../components/ScheduleItem.vue';
import EDCEmpty from '../components/Empty.vue';
import EDCServiceTypeFilter from '../components/ServiceTypeFilter.vue';
import EDCServiceDetail from '../components/ServiceDetail.vue';
import EDCSpinner from '../components/Spinner.vue';

import { Auth } from '@/services/auth';
import { BusinessesAPI } from '@/services/businesses';
import { AuthModal } from '@/scripts/open-auth';
import { RatingAPI } from '@/services/rating';
import { Alert } from '@/scripts/open-alert.js';

export default {
    name: "sen-business-detail",
    components: {
        EDCHeader,
        EDCHead,
        EDCMobileNav,
        EDCFooter,
        EDCButton,
        EDCStars,
        EDCScheduleItem,
        EDCEmpty,
        EDCServiceTypeFilter,
        EDCServiceDetail,
        EDCSpinner

    },
    data() {
        return {
            activeTab: 'info',
            rate: {
                stars: null,
                comment: null
            },
            rate_form: false,
            logged_in: false,
            rating: {
                list: null,
                average: null,
                count: null,
            },
            images_slider: [],
            info: {},
            services_filter: "PLA",
            services: [],
            service_detail: {
                modal: false,
                data: null
            },
            spinner: false

        }
    },
    methods: {
        /**
        * @param {("info" | "services" | "ratings")} tabName
        * @description sets the current tab
        */
        setTab(tabName) {
            this.activeTab = tabName
        },
        showRateModal(show) {
            if (!show) {
                this.rate_form = false
                this.rate = { comment: null, stars: null }
            } else {
                this.rate_form = true
            }
        },
        onRateModalSubmit (event) {
            event.preventDefault()
            const businessID = this.$route.params.id;
            this.showSpinner(true)
            RatingAPI.sendRating(this.rate.stars, businessID ,this.rate.comment).then(data => {
                this.showSpinner(false)
                if (data.status === 400) {
                    this.login.error = data.msg
                } else {
                    Alert.open("success", "Reseña enviada correctamente")
                    this.showRateModal(false)
                    this.getBusinessReviews()
                }
            })
        },
        onAuthenticationChange(payload) {
            const status = payload.logged_in;
            this.logged_in = status;
        },
        onFormStarClicked(number) {
            this.rate.stars = number;
        },
        getBusinessInfo() {
            const businessID = this.$route.params.id;

            this.showSpinner(true)
            BusinessesAPI.getBusiness(businessID).then(data => {
                this.showSpinner(false)
                if (data) {
                    this.info = data
                }
            })
        },
        getBusinessType(code) {
            const codes = {
                RES: 'Restaurante',
                TRA: 'Transporte',
                HOS: 'Hospedaje',
                TOU: 'Tour'
            }

            return codes[code]
        },
        getBusinessServices(type) {
            const businessID = this.$route.params.id;

            BusinessesAPI.getServices(businessID, type).then(data => {
                this.services = data.results
            })
        },
        getBusinessReviews() {
            const businessID = this.$route.params.id;
    
            BusinessesAPI.getReviews(businessID).then(data => {
                this.rating.list = data.results;

                this.rating.average = `${data.average}`;
                this.rating.count = data.count;
                this.rating.own = Object.keys(data.user_comment).length != 0 ? true : false
                if (data.user_comment?.user) {
                    this.rating.list.unshift(data.user_comment)
                    this.rating.count += 1;
                }
            })
        },
        openServiceModal(open) {
            this.service_detail.modal = open
        },
        getServiceDetail(id) {
            this.openServiceModal(true)
            BusinessesAPI.getServiceDetails(id).then(data => {
                this.service_detail.data = data
            })
        },
        openRegisterModal() {
            AuthModal.openModal("register")
        },
        showSpinner(show) {
            this.spinner = show
        }
    },
    async mounted() {
        this.logged_in = Auth.isAuthenticated()
        Auth.subscribe(this.onAuthenticationChange)

        this.images_slider = new Splide('#images-splide', {
            type: "slide",
            perPage: 1,
            speed: 750,
            arrows: false,
            padding: '24px',
            pauseOnHover: false,
            pagination: false
        }).mount()

        const lightbox = new PhotoSwipeLightbox({
            gallery: '#gallery',
            children: 'a',
            pswpModule: () => import('photoswipe')
        });
        lightbox.init();

        this.getBusinessInfo()
        this.getBusinessReviews()
        this.getBusinessServices(this.services_filter)
    },
    beforeUnmount() {
        Auth.unsubscribe(this.onAuthenticationChange)
    }
}
</script>

<style>
/* HERO */
.b-details__hero {
    width: 100%;
    height: 320px;
    overflow: hidden;
    position: relative;
}

.b-details__hero-bk {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -1;
}

.b-details__hero-bk::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .65);
}

.b-details__hero-bk img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.b-details__hero-content {
    max-width: 1000px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    gap: 6px;
    padding: 24px;
    margin: 0 auto;
}

.b-details__hero-title {
    font-size: 2.25rem;
    text-align: center;
    color: white;
}

/* TABS */
.b-details__tabs {
    width: 100%;
    padding: 0px 24px;
    display: flex;
    justify-content: center;
    background-color: var(--color-light);
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.b-details__tabs-content {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.b-details__tab-item {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    border: none;
    outline: none;
    font-size: 1rem;
    position: relative;
    color: var(--color-dark);
    font-weight: 500;
}

.b-details__tab-item.active {
    color: var(--color-primary);
}

.b-details__tab-item.active::after {
    content: '';
    width: 75%;
    height: 4px;
    position: absolute;
    bottom: 0;
    background-color: var(--color-primary);
    border-radius: 8px;
}

.b-details__info {
    width: 100%;
    max-width: 1000px;
    padding: 24px;
    margin: 0 auto;
}

.b-details__all-info {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

/* TAB INFO */
.b-details__general-info {
    width: 100%;
}

.b-images-splide__container {
    width: 100%;
    margin: 0px -24px;
    margin-bottom: 24px;
}

.b-image-slide {
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
}

.b-image-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.b-details__type {
    color: var(--color-dark);
    font-size: 1rem;
    font-weight: 400;
}

.b-details__name {
    color: var(--color-dark);
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 2px;
}

.b-details__stars {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
}

.b-details__stars-content {
    display: flex;
    align-items: center;
    gap: 8px;
}

.b-details__stars .no-stars {
    opacity: 0.5;
}

.b-details__stars p {
    position: relative;
    top: 3px;
}

.b-details__stars-list {
    display: flex;
    gap: 2px;
}

.b-details__pricing {
    display: flex;
    align-items: center;
    gap: 8px;
}

.b-details__pricing-list {
    display: flex;
    align-items: center;
}

.b-details__address {
    margin-top: 16px;
    color: var(--color-dark);
}

.b-details__city {
    margin-bottom: 16px;
    color: var(--color-dark);
}

.b-details__description {
    margin-bottom: 24px;
    font-size: 16px;
    color: var(--color-dark);
}

.b-details__services-list {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.b-details__service-item {
    display: flex;
    align-items: center;
    gap: 6px;
    fill: var(--color-primary);
    opacity: 0.5;
}

.b-details__service-item svg {
    fill: inherit;
    filter: grayscale(1);
}

.b-details__service-item.active {
    opacity: 1;
}

.b-details__service-item.active svg {
    filter: none;
}

.b-details__phones, .b-details__emails {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}

.b-details__phones::before,
.b-details__emails::before,
.b-details__schedule::before,
.b-details__file-list::before {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 6px;
    color: var(--color-dark);
    display: block;
}

.b-details__phones::before { content: Teléfonos }
.b-details__emails::before { content: 'Correos:' }
.b-details__schedule::before { content: 'Horario:' }
.b-details__file-list::before { content: 'Visualiza:' }

.b-details__phone-item,
.b-details__email-item {
    margin-bottom: 2px;
    color: var(--color-dark);
}

.b-details__schedule {
    margin-bottom: 24px;
}

.b-details__schedule-list {
    margin-top: 8px;
}

.b-details__file-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;
}

.b-details__file-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6px;
    font-size: 16px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    color: var(--color-dark);
    padding: 12px 12px;
}

.b-details__file-name {
    font-weight: bold;
    font-size: 1.1rem;
}

.b-details__download-file {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    outline: none;
    padding: 2px;
    cursor: pointer;
    fill: var(--color-primary);
}

.b-details__download-file svg {
    width: 100%;
    height: 100%;
    fill: inherit;
}

/* SERVICES */
.b-details__services {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.b-details__services-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
 
.b-details__service {
    width: 100%;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.b-details__service-image {
    max-height: 135px;
    min-width: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.b-details__service-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.b-details__service-info {
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
}

.b-details__service-title {
    font-size: 1.1rem;
    font-weight: bold;
    color: var(--color-dark);
}

.b-details__service-description {
    font-size: 1rem;
    font-weight: normal;
    color: var(--color-dark);
}

/* RATING */
.b-details__ratings {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.b-details__rating-head {
    width: 100%;
}

.rating-avg {
    width: 100%;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
}

.rating-avg__value {
    font-size: 2rem;
    position: relative;
    top: 2px;
}

.rating-avg__details {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.rating-own {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
    background-color: #f3f3f3;
    border: 2px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
}

.rating-own__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.rating-authenticated {
    padding: 24px;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 32px;
}

.rating-authenticated__description {
    text-align: center;
}

.rating-authenticated button {
    max-width: 180px;
}

.rate-form {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.rate-form__overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .75);
    z-index: -1;
}

.rate-form__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    gap: 24px;
    padding: 32px;
    margin: 24px;
    position: relative;
}

.rate-form__ratings-stars {
    display: flex;
    justify-content: center;
    gap: 6px;
}

.rate-form__input-stars {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 70px;
}

.rate-form__ratings-stars img {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.rate-form__input-comment {
    width: 100%;
    height: 120px;
    resize: vertical;
}

.rate-form__options {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.rate-form__close {
    font-weight: bold;
    color: var(--color-primary);
    font-size: 1.1rem;
    text-decoration: underline;
    cursor: pointer;
}

.b-details__rating-list-title {
    width: 100%;
    margin-bottom: 24px;
}

.b-details__rating-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.rate-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.rate-item__stars {
    display: flex;
    gap: 2px;
}

.rate-item__star {
    width: 18px;
    height: 18px;
} 

@media (min-width: 500px) {
    .b-details__service { flex-direction: row; }
    .b-details__service-image { max-height: 100px; max-width: 80px }
}

@media (min-width: 920px) {
    .b-details__all-info { display: flex; gap: 32px; flex-direction: row; }
    .b-details__schedule { min-width: 320px }
    .b-details__services-list { display: grid; grid-template-columns: 1fr 1fr; }
    .b-details__phones-emails { display: grid; grid-template-columns: 1fr 1fr; }
    .b-details__service { flex-direction: row; }
    .b-details__service-image { max-height: 100px; max-width: 80px; }
}
</style>