<template>
    <div class="edc-municipio" @click="navigateToMunicipio(id)">
        <div class="edc-municipio__image">
            <img :src="image" />
        </div>
        <p class="edc-municipio__name">{{ name }}</p>
    </div>
</template>

<script>
export default {
    name: "edc-municipio",
    props: {
        id: {
            type: Number,
            required: true
        },
        image: {
            type: String,
            required: true,
            default: "https://picsum.photos/800/800?random=1930"
        },
        name: {
            type: String,
            required: true,
            default: 'Nombre de municipio'
        }
    },
    methods: {
        navigateToMunicipio(id) {
            this.$router.push({ name: 'municipio', params: { id: id }})
        }
    }
}
</script>

<style>
.edc-municipio {
    width: 100%;
    height: 200px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.edc-municipio::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(29, 29, 29, 0.00) 0%, #1D1D1D 100%);
}

.edc-municipio__image {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.edc-municipio__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.edc-municipio__name {
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 20px;
    font-size: 1.25rem;
    color: white;
    font-weight: bold;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.50);
    z-index: 9;
}
</style>