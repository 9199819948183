<template>
    <router-view />
    <EDCAlert :type="alert.type" :message="alert.message" :isOpen="alert.open" />
    <EDCAuthModal :type="modal.type" :visible="modal.open" @close="closeModal" @change="changeModal" />
    <EDCSpinner type="fixed" :show="spinner_open" />
</template>

<script>
import { AuthModal } from './scripts/open-auth';
import { Spinner } from './scripts/open-spinner';
import { Alert } from './scripts/open-alert';

import EDCAuthModal from './modals/Auth.vue';
import EDCSpinner from './components/Spinner.vue';
import EDCAlert from './components/Alert.vue';

export default {
    components: {
        EDCAuthModal,
        EDCSpinner,
        EDCAlert
    },
    data() {
        return {
            spinner_open: false,
            modal: {
                open: false,
                type: 'login',
            },
            alert: {
                open: false,
                type: 'info',
                message: "Mensaje de prueba para la alerta"
            }
        }
    },
    mounted() {
        AuthModal.subscribe(this.openModal)
        Spinner.subscribe(this.openSpinner)
        Alert.subscribe(this.openAlert)
    },
    beforeMount() {
        AuthModal.unsubscribe(this.openModal)
        Spinner.unsubscribe(this.openSpinner)
        Alert.unsubscribe(this.openAlert)
    },
    methods: {
        openModal(data) {
            this.modal.open = data.open;
            this.modal.type = data.modal;
        },
        changeModal(modal) {
            AuthModal.openModal(modal);
        },
        closeModal() {
            this.modal.open = false;
            this.modal.type = "login";
        },
        openSpinner(payload) {
            if(payload.open === false) {
                this.spinner_open = false
            } else {
                this.spinner_open = true
            }
        },
        openAlert(payload) {
            this.alert.type = payload.type;
            this.alert.message = payload.message;
            this.alert.open = true;

            setTimeout(() => {
                this.closeAlert()
            }, 3500);
        },
        closeAlert() {
            this.alert.open = false
        }
    }
}
</script>

<style>
</style>
