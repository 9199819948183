<template>
    <div class="edc-head">
        <div class="content">
            <a class="edc-head__back" href="#" @click="$router.back()">
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                    <path d="M13.6937 22.75L22.2687 31.325C22.6187 31.675 22.7867 32.0834 22.7727 32.55C22.7587 33.0167 22.5761 33.425 22.2249 33.775C21.8749 34.0959 21.4666 34.2639 20.9999 34.279C20.5333 34.2942 20.1249 34.1262 19.7749 33.775L8.22493 22.225C8.04993 22.05 7.92568 21.8604 7.85218 21.6563C7.77868 21.4521 7.74251 21.2334 7.74368 21C7.74368 20.7667 7.78043 20.5479 7.85393 20.3438C7.92743 20.1396 8.0511 19.95 8.22493 19.775L19.7749 8.22502C20.0958 7.90419 20.4971 7.74377 20.9789 7.74377C21.4608 7.74377 21.8761 7.90419 22.2249 8.22502C22.5749 8.57502 22.7499 8.99094 22.7499 9.47277C22.7499 9.95461 22.5749 10.3699 22.2249 10.7188L13.6937 19.25H33.2499C33.7458 19.25 34.1617 19.418 34.4977 19.754C34.8337 20.09 35.0011 20.5054 34.9999 21C34.9999 21.4959 34.8319 21.9118 34.4959 22.2478C34.1599 22.5838 33.7446 22.7512 33.2499 22.75H13.6937Z" fill="#503B2F"/>
                </svg>
                <p>{{ title }}</p>
            </a>
            <!-- <p class="title">{{ title }}</p> -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'edc-head',
    props: {
        title: {
            type: String,
            required: true
        }
    }
}
</script>

<style>
.edc-head {
    width: 100%;
    height: 64px;
    background-color: var(--color-light);
    display: flex;
    justify-content: center;
    z-index: 9999;
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.edc-head .content {
    height: 100%;
    width: 100%;
    max-width: 1000px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
}

.edc-head__back {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
}

.edc-head__back svg {
    width: 34px;
    height: 34px;
}

.edc-head__back p {
    font-size: 1.35rem;
    font-weight: bold;
    color: var(--color-dark);
    position: relative;
    top: 2px;
}

@media(min-width: 920px) {
    .edc-head .content { padding: 0px 24px }
}
</style>