<template>
    <EDCHeader />
    <EDCHead title="Detalles de noticia" />
    <div class="new-details__content">
        <img :src="details.image" class="new-details__image">
        <div class="new-details__body">
            <p class="new-details__date">12 de Octubre - 2023</p>
            <h1 class="new-details__title">{{ details.title }}</h1>
            <div class="new-details__text" v-html="details.description"></div>
        </div>
    </div>
    <EDCFooter />
    <EDCMobileNav />
</template>

<script>
import EDCHeader from '../components/Header.vue';
import EDCHead from '../components/Head.vue';
import EDCFooter from '../components/Footer.vue';
import EDCMobileNav from '../components/MobileNav.vue';

import { NewsAPI } from '@/services/news';
import { Spinner } from '@/scripts/open-spinner';

export default {
    name: "edc-new-details-view",
    components: {
        EDCHeader,
        EDCHead,
        EDCFooter,
        EDCMobileNav
    },
    data() {
        return {
            details: { title: null, image: null, description: null }
        }
    },
    mounted() {
        const newID = this.$route.params.id;

        Spinner.show()
        NewsAPI.getNew(newID).then(data => {
            Spinner.hide()
            this.details = data;
        })
    }
}
</script>

<style>
.new-details__content {
    width: 100%;
    max-width: 1000px;
    padding: 0px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.new-details__body { padding: 0px 24px }

.new-details__image {
    width: 100%;
    height: 350px;
    object-fit: cover;
    margin-bottom: 24px;
}

.new-details__title {
    width: 100%;
    line-height: 110%;
    margin-bottom: 16px;
}

@media (min-width: 920px) {
    .new-details__content { padding: 0px 24px }
    .new-details__body { padding: 0px }
}
</style>