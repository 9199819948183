<template>
    <div class="edc-business" @click="goToDetails()">
        <div class="edc-business__bk">
            <img :src="image" >
            <div class="edc-business__stars">
                <EDCStars
                :quantity="`${stars}`" 
                :star_size="20"
                v-show="stars > 0" />
                <p class="no-stars" v-show="stars <= 0">Sin calificaciones</p>
            </div>
        </div>
        <div class="edc-business__info">
            <p class="edc-business__info-type">{{ getType(type) }}</p>
            <p class="edc-business__info-title">{{ name }}</p>
        </div>
    </div>
</template>

<script>
import EDCStars from '../components/Stars.vue';

export default {
    name: "edc-business",
    components: {
        EDCStars
    },
    props: {
        id: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: true,
            validator(value) {
                return ['RES', 'HOS', 'TOU', 'TRA'].includes(value)
            }
        },
        stars: {
            type: Number,
            min: 0,
            max: 5,
            required: true
        },
        image: {
            type: String,
            required: true,
            default: "https://picsum.photos/800/800?random=1930"
        },
        name: {
            type: String,
            required: true,
            default: 'Nombre de la experiencia'
        }
    },
    methods: {
        getType(type) {
            const types = {
                RES: 'Restaurante',
                HOS: 'Estadía',
                TOU: 'Tour',
                TRA: 'Transporte'
            }

            return types[type]
        },
        getStars() {
            const arr = []

            for (let i = 1; i <= 5; i++) {
                if (i <= this.stars) {
                    arr.push('active')
                } else {
                    arr.push('')
                }
            }

            return arr;
        },
        goToDetails() {
            this.$router.push({ name: 'business-details', params: { id: this.id } })
        }
    }
}
</script>

<style>
.edc-business {
    width: 100%;
    height: 240px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
    border: 1px solid rgba(80, 59, 47, 0.15);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    background-color: white;
}

.edc-business__bk {
    width: 100%;
    height: 150px;
    min-height: 150px;
    position: relative;
    background-color: lightgray;
}

.edc-business__bk::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 120px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
}

.edc-business__bk img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.edc-business__stars {
    position: absolute;
    left: 12px;
    bottom: 12px;
    display: flex;
    gap: 4px;
}

.edc-business__stars .no-stars {
    color: white;
    opacity: 0.5;
}

.edc-business__info {
    height: 100%;
    width: 100%;
    padding: 14px 18px;
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 0px 0px 16px 16px;
    overflow: hidden;
}

.edc-business__info-title {
    height: 100%;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 120%;
    overflow: hidden;
    position: relative;
}

.edc-business__info-type {
    font-weight: 400;
    font-size: 0.9rem;
}
</style>