<template>
    <router-link class="edc-new" :to="{ name: 'new-details', params: { id } }">
        <div class="edc-new__bk">
            <img :src="image" >
        </div>
        <div class="edc-new__info">
            <p class="edc-new__info-date">{{ date }}</p>
            <p class="edc-new__info-title">{{ name }}</p>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "edc-new",
    props: {
        id: {
            type: Number,
            required: true
        },
        date: {
            type: String,
            required: true
        },
        image: {
            type: String,
            required: true,
            default: "https://picsum.photos/800/800?random=1930"
        },
        name: {
            type: String,
            required: true,
            default: 'Nombre de la noticia'
        }
    },
}
</script>

<style>
.edc-new {
    width: 100%;
    height: 240px;
    position: relative;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    cursor: pointer;
    border: 1px solid rgba(80, 59, 47, 0.15);
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);
    background-color: white;
    text-decoration: none;
    color: var(--color-dark);
}

.edc-new__bk {
    width: 100%;
    height: 150px;
    position: relative;
}

.edc-new__bk img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.edc-new__info {
    height: 100%;
    width: 100%;
    padding: 14px 18px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 0px 0px 16px 16px;
    overflow: hidden;
}

.edc-new__info-title {
    height: 100%;
    font-weight: bold;
    font-size: 1.2rem;
    line-height: 120%;
    overflow: hidden;
    position: relative;
}

.edc-new__info-date {
    font-weight: 400;
    font-size: 0.9rem;
}
</style>