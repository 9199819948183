<template>
    <div class="edc-service-filter">
        <div class="edc-service-filter__content">
            <div class="edc-service-filter__item" @click="selectFilter('PLA')" :class="[selected === 'PLA' ? 'active' : '']">
                <svg class="edc-service-filter__item-icon" xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34" >
                    <path d="M2.29163 21.25C2.29163 18.6763 3.36593 16.6165 5.51454 15.0705C7.66315 13.5244 10.1305 12.7509 12.9166 12.75C15.7027 12.75 18.1701 13.5235 20.3187 15.0705C22.4673 16.6175 23.5416 18.6773 23.5416 21.25H2.29163ZM2.29163 26.9166V24.0833H23.5416V26.9166H2.29163ZM2.29163 32.5833V29.75H23.5416V32.5833H2.29163ZM26.375 32.5833V21.25C26.375 18.5583 25.4541 16.2326 23.6125 14.2729C21.7708 12.3132 19.5159 11.0027 16.8479 10.3416L16.4583 7.08329H23.5416V1.41663H26.375V7.08329H33.4583L30.9083 32.5833H26.375Z" />
                </svg>
                <p class="edc-service-filter__item-label">Comida</p>
            </div>
            <div class="edc-service-filter__item" @click="selectFilter('HOS')" :class="[selected === 'HOS' ? 'active' : '']">
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34">
                    <path d="M4.875 29.75V26.9167H7.70833V4.25H21.875V5.66667H27.5417V26.9167H30.375V29.75H24.7083V8.5H21.875V29.75H4.875ZM16.2083 18.4167C16.6097 18.4167 16.9464 18.2807 17.2184 18.0087C17.4904 17.7367 17.6259 17.4004 17.625 17C17.625 16.5986 17.489 16.2619 17.217 15.9899C16.945 15.7179 16.6088 15.5824 16.2083 15.5833C15.8069 15.5833 15.4702 15.7193 15.1982 15.9913C14.9262 16.2633 14.7907 16.5996 14.7917 17C14.7917 17.4014 14.9277 17.7381 15.1997 18.0101C15.4717 18.2821 15.8079 18.4176 16.2083 18.4167Z" />
                </svg>
                <p class="edc-service-filter__item-label">Estadía</p>
            </div>
            <div class="edc-service-filter__item" @click="selectFilter('TRA')" :class="[selected === 'TRA' ? 'active' : '']">
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34" >
                    <path d="M11.7083 29.75H7.45829V25.4292C7.03329 24.957 6.69093 24.4314 6.43121 23.8525C6.17149 23.2735 6.04163 22.6422 6.04163 21.9584V8.50004C6.04163 6.54032 6.95065 5.10571 8.76871 4.19621C10.5868 3.28671 13.4555 2.83243 17.375 2.83338C21.4361 2.83338 24.3402 3.27018 26.0875 4.14379C27.8347 5.0174 28.7083 6.46949 28.7083 8.50004V21.9584C28.7083 22.6431 28.5784 23.2749 28.3187 23.8539C28.059 24.4328 27.7166 24.9579 27.2916 25.4292V29.75H23.0416V26.9167H11.7083V29.75ZM8.87496 14.1667H25.875V9.91671H8.87496V14.1667ZM12.4166 22.6667C13.0069 22.6667 13.5089 22.4599 13.9225 22.0462C14.3362 21.6325 14.5426 21.131 14.5416 20.5417C14.5416 19.9514 14.3348 19.4495 13.9211 19.0358C13.5075 18.6221 13.006 18.4158 12.4166 18.4167C11.8263 18.4167 11.3244 18.6235 10.9107 19.0372C10.497 19.4509 10.2907 19.9524 10.2916 20.5417C10.2916 21.132 10.4985 21.634 10.9121 22.0476C11.3258 22.4613 11.8273 22.6677 12.4166 22.6667ZM22.3333 22.6667C22.9236 22.6667 23.4255 22.4599 23.8392 22.0462C24.2529 21.6325 24.4592 21.131 24.4583 20.5417C24.4583 19.9514 24.2515 19.4495 23.8378 19.0358C23.4241 18.6221 22.9226 18.4158 22.3333 18.4167C21.743 18.4167 21.241 18.6235 20.8274 19.0372C20.4137 19.4509 20.2073 19.9524 20.2083 20.5417C20.2083 21.132 20.4151 21.634 20.8288 22.0476C21.2425 22.4613 21.744 22.6677 22.3333 22.6667Z" />
                </svg>
                <p class="edc-service-filter__item-label">Rutas</p>
            </div>
            <div class="edc-service-filter__item" @click="selectFilter('TOU')" :class="[selected === 'TOU' ? 'active' : '']">
                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="34" viewBox="0 0 35 34" >
                    <path d="M17.125 17C17.9041 17 18.5714 16.7224 19.1267 16.167C19.682 15.6117 19.9592 14.9449 19.9583 14.1667C19.9583 13.3875 19.6806 12.7203 19.1253 12.165C18.57 11.6096 17.9032 11.3324 17.125 11.3334C16.3458 11.3334 15.6785 11.611 15.1232 12.1664C14.5679 12.7217 14.2907 13.3885 14.2916 14.1667C14.2916 14.9459 14.5693 15.6131 15.1246 16.1685C15.68 16.7238 16.3467 17.001 17.125 17ZM17.125 31.1667C13.3236 27.932 10.4846 24.9277 8.60796 22.1539C6.73135 19.38 5.79257 16.8121 5.79163 14.45C5.79163 10.9084 6.9311 8.08685 9.21004 5.98546C11.489 3.88407 14.1273 2.83337 17.125 2.83337C20.1236 2.83337 22.7623 3.88407 25.0413 5.98546C27.3202 8.08685 28.4592 10.9084 28.4583 14.45C28.4583 16.8112 27.5195 19.3791 25.642 22.1539C23.7644 24.9286 20.9254 27.9329 17.125 31.1667Z" />
                </svg>
                <p class="edc-service-filter__item-label">Tours</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "edc-service-filter",
    data () {
        return {
            selected: 'PLA'
        }
    },
    methods: {
        selectFilter(type) {
            this.selected = type
            this.$emit('filter_change', type)
        }
    }
}
</script>

<style>
.edc-service-filter {
    width: 100%;
}

.edc-service-filter__content {
    width: 100%;
    display: flex;
    gap: 0;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    background-color: white;
    overflow: hidden;
}

.edc-service-filter__item {
    width: 100%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    color: var(--color-dark);
    fill: var(--color-dark);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: none;
    border-top: none;
    cursor: pointer;
    position: relative;
}

.edc-service-filter__item.active {
    color: var(--color-primary);
    fill: var(--color-primary);
    background: rgba(161, 83, 170, 0.15);
}

.edc-service-filter__item.active::after {
    content: '';
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 4px;
    background-color: var(--color-primary);
}

.edc-service-filter__item-label {
    font-size: 0.8rem;
    color: inherit;
}

.edc-service-filter__item-icon {
    fill: inherit;
}

@media (min-width: 920px) {
    .edc-service-filter__item { height: 60px; flex-direction: row; justify-content: flex-start; padding-left: 16px; }
}
</style>