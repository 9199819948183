<template>
    <EDCHeader />
    <EDCHead title="Negocios del municipio" />
    <div class="municipio-businesses__body">
        <EDCServiceTypeFilter @filter_change="onFilterApplied" />
        <div class="municipio-businesses__body-content">
            <EDCEmpty message="No se han encontrado negocios de esta categoria" v-show="!businesses.length && !spinner" />
            <EDCSpinner type="inline" :show="spinner" />
            <div class="municipio-businesses__list" v-show="businesses.length">
                <EDCBusiness 
                v-for="(business, idx) in businesses"
                :key="business.pk"
                :id="business.pk"
                :type="business.type" 
                :name="business.name" 
                :stars="parseInt(business.rating)" 
                :image="`https://picsum.photos/800/800?random=${idx}`" />
            </div>
            <EDCButton label="Cargar más" v-show="next" @click="onNextButton()" />
        </div>
    </div>
    <EDCFooter />
    <EDCMobileNav />
</template>

<script>
import EDCHeader from '../components/Header.vue';
import EDCHead from '../components/Head.vue';
import EDCFooter from '../components/Footer.vue';
import EDCMobileNav from '../components/MobileNav.vue';
import EDCBusiness from '../components/Business.vue';
import EDCButton from '../components/Button.vue';
import EDCServiceTypeFilter from '../components/ServiceTypeFilter.vue';
import EDCEmpty from '../components/Empty.vue';
import EDCSpinner from '@/components/Spinner.vue';

import { MUNICIPIO_CODES } from '../scripts/municipio-codes';
import { BusinessesAPI } from '@/services/businesses';
import { Spinner } from '@/scripts/open-spinner';

export default {
    name: "edc-municipio-businesses-view",
    components: {
    EDCHeader,
    EDCHead,
    EDCFooter,
    EDCMobileNav,
    EDCBusiness,
    EDCButton,
    EDCServiceTypeFilter,
    EDCEmpty,
    EDCSpinner
},
    data() {
        return {
            businesses: [],
            municipio: null,
            type_filter: "RES",
            next: null,
            spinner: false
        }
    },
    async mounted() {
        Spinner.show()
        await this.getFilteredBusinesses(this.type_filter)
        Spinner.hide()
    },
    methods: {
        showSpinner(show) {
            this.spinner = show
        },
        async getFilteredBusinesses(type) {
            const id = parseInt(this.$route.params.id);
            const municipio_idx = MUNICIPIO_CODES.findIndex(municipio => municipio.id === id)
            const municipio = MUNICIPIO_CODES[municipio_idx]

            this.showSpinner(true)
            await BusinessesAPI.businessesFiltered(municipio.code, type).then(data => {
                this.showSpinner(false)
                this.businesses = data.results;
                this.next = data.next;
            })
        },
        onFilterApplied(type) {
            const types = {
                PLA: "RES",
                HOS: "HOS",
                TRA: "TRA",
                TOU: "TOU"
            }

            this.getFilteredBusinesses(types[type])
        },
        onNextButton() {
            fetch(this.next)
            .then(res => res.json())
            .then(data => {
                const results = data.data.results;

                if (results) {
                    this.next = data.data.next;

                    results.forEach(exp => {
                        this.businesses.push(exp)
                    })
                }
            })
        }
    }
}
</script>

<style>
/* BODY */
.municipio-businesses__body {
    width: 100%;
    max-width: 1000px;
    padding: 24px;
    margin: 0px auto;
    margin-bottom: 40px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.municipio-businesses__municipio__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.municipio-businesses__body-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.municipio-businesses__body button {
    max-width: 250px;
    margin: 0 auto;
}

.municipio-businesses__list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
    gap: 16px;
}
</style>